<template>
    <el-dialog
        v-model="loginDialog.show"
        width="1080px"
        class="class-dialog"
        :show-header="false"
        :before-close="handleClose"
    >
        <el-icon @click="handleClose" class="close" size="16px" color="#ccc">
            <CloseBold/>
        </el-icon>
        <div class="content">
            <div class="left">
                <img src="@/assets/login/bg.png" alt=""/>
            </div>
            <div class="right">
                <img :src="cms_config.logo" alt="" class="logo" style="margin-top: 40px;height:55px;width: auto;"/>
                <div class="edit" style="display: flex;justify-content: center;align-items: center;">
                    <div :class="type === type_list['phone'] ? 'chosed_login_type' : 'chose_login_type'" @click="type = type_list['phone'];main_btn_str='登录'">
                        <el-icon size="25" :color="type === type_list['phone'] ? '#e93a29' : 'e93a29'">
                            <User/>
                        </el-icon>
                        手机号登录
                    </div>
                    <div :class="type === type_list['card'] ? 'chosed_login_type' : 'chose_login_type'" @click="type = type_list['card'];main_btn_str='登录'">
                        <el-icon size="25" :color="type === type_list['card'] ? '#e93a29' : 'e93a29'">
                            <CreditCard/>
                        </el-icon>
                        会员卡登录
                    </div>
                </div>
                <div class="edit">
                    <div class="select" v-if="type === type_list['card']">
                        <div class="name">卡号</div>
                        <div class="ipt">
                            <el-input v-model="form.card_num" style="width:100%" placeholder="请输入卡号"></el-input>
                        </div>
                    </div>
                    <div class="select"
                         v-if="type === type_list['phone'] || type === type_list['register'] || type === type_list['forgot_pass'] || type === type_list['pass_login']">
                        <div class="name">手机号</div>
                        <div class="ipt">
                            <el-input v-model="form.phone" style="width:100%" placeholder="手机号"></el-input>
                        </div>
                    </div>
                    <div class="select" v-if="type !== type_list['phone'] && type !== type_list['card']">
                        <div class="name">验证码</div>
                        <div class="ipt">
                            <el-input v-model="form.captcha" style="width:100%" placeholder="请输入验证码">
                                <template #append>
                                    <div style="height: 100%;cursor: pointer;" @click="get_code">
                                        {{ user_entity_btn_txt }}
                                    </div>
                                </template>
                            </el-input>
                        </div>
                    </div>
                    <div class="select" v-if="type === type_list['phone'] || type === type_list['card']">
                        <div class="name">密码</div>
                        <div class="ipt">
                            <el-input v-model="form.passwd" type="password" style="width:100%"
                                      placeholder="请输入密码"></el-input>
                        </div>
                    </div>
                    <div class="select" v-if="type === type_list['register'] || type === type_list['forgot_pass']">
                        <div class="name">输入密码</div>
                        <div class="ipt">
                            <el-input v-model="form.new_password" style="width:100%" type="password"
                                      placeholder="请输入密码"></el-input>
                        </div>
                    </div>
                    <div class="select" v-if="type === type_list['register'] || type === type_list['forgot_pass']">
                        <div class="name">确认密码</div>
                        <div class="ipt">
                            <el-input v-model="form.verify_password" style="width:100%" type="password"
                                      placeholder="请输入密码"></el-input>
                        </div>
                    </div>
                    <div class="ms-button" @click="login">
                        {{ main_btn_str }}
                    </div>
                </div>
                <div class="way" style="width:460px;display: flex;justify-content: space-between;align-items: center;height: 30px;line-height: 30px;font-size: 14px;">
                    <div style="cursor: pointer;" @click="change_login_type(type_list['pass_login'])"
                         v-if="type === type_list['phone']">验证码登录
                    </div>
                    <div v-else></div>
                    <div
                        style="display: flex;justify-content: right;align-items: center;height: 30px;line-height: 30px;font-size: 14px;">
                        <div style="cursor: pointer;margin-right: 30px;"
                             v-if="type === type_list['phone']"
                             @click="change_login_type(type_list['forgot_pass'])">忘记密码
                        </div>
                        <div style="cursor: pointer;" @click="show_user_agreement">注册</div>
                    </div>
                </div>
                <div class="way" style="margin-bottom: 10px;">
                    <div class="list" @click="change_login_type(type_list['weixin'])" style="display: none;">
                        <img src="@/assets/login/wechat.png" alt="">
                        <div class="font">微信登录</div>
                    </div>
                    <div class="list" @click="change_login_type(type_list['phone'])" style="display: none;">
                        <img src="@/assets/login/phone.png" alt="">
                        <div class="font">手机登录</div>
                    </div>
                    <div class="list" @click="change_login_type(type_list['card'])" style="display: none;">
                        <div
                            style="height:50px;width:50px;border-radius: 50%;background-color: #409EFF;display: flex;justify-content: center;align-items: center;">
                            <el-icon size="30" style="color:white;">
                                <Postcard/>
                            </el-icon>
                        </div>
                        <div class="font">卡号登录</div>
                    </div>
                </div>
            </div>
        </div>
        <check_huadong :show_check_huadong="show_check_huadong" @check_huadong_success="send_code"></check_huadong>
    </el-dialog>
    <user_agreement_remind :show_remind="show_remind" :content="user_agreement"
                           @show_result="show_result"></user_agreement_remind>
</template>
<script>
import env from "@/utils/env";
import {ElLoading, ElNotification} from "element-plus";
import axios from "axios";
import api from "@/api";
import entity from "@/utils/entity";
import Check_huadong from "@/layout/comps/check_huadong.vue";
import User_agreement_remind from "@/views/subject/conps/user_agreement_remind.vue";
import {useInfoStore} from '@/store/index'

export default {
    props: {
        loginDialog: {
            typeof: Object,
            default: {
                show: false,
            },
        },
        bind_phone: {
            typeof: Function,
            default: null,
        },
        show_huadong_fun: {
            typeof: Function,
            default: null,
        },
        can_send_code: {
            typeof: Number,
            default: 0,
        },
        add_base_info: {
            typeof: Function,
            default: null,
        },
    },
    components: {
        User_agreement_remind,
        Check_huadong,
    },
    watch: {
        loginDialog(info) {
        },
        can_send_code(info) {
            this.send_code()
        }
    },
    data() {
        return {
            config: {
                mode: 'math', // 验证码类型：math（算术验证码），char（字符验证码）
                length: 4, // 验证码长度
                width: 200, // 验证码宽度
                height: 50, // 验证码高度
                font_size: 30, // 字体大小
            },
            id: '',
            psd: '',
            type: 1,
            show_remind: 0,
            show_check_huadong: 0,
            main_btn_str: '登录',
            form: {
                'card_num': '',
                'phone': '',
                'passwd': '',
                'captcha': '',
                'new_password': '',
                'verify_password': '',
            },
            type_list: {
                'card': 1,
                'phone': 2,
                'code': 3,
                'weixin': 4,
                'register': 5,
                'forgot_pass': 6,
                'pass_login': 7
            },
            main_btn_list: {
                '1': '登录',
                '2': '登录',
                '3': '登录',
                '4': '登录',
                '5': '注册',
                '6': '重置密码',
                '7': '登录'
            },
            state: '',
            forgetPassword: 0,
            phoneRegister: 0,
            need_reload: false,
            is_loading: false,
            show_qr_code_login: true,
            phone_passwd_login: false,
            phone_captcha_login: true,
            show_qr_code: false,
            timer: undefined,
            time_count: 0,
            //agent: 'WCMC5XZE9313',
            agent: '',
            user_entity_btn_txt: '获取验证码',
            user_entity_disabled_click: false,
            user_entity_phone_time: 60,
            oss_list: {},
            oss_url_list: {},
            agent_list_url: '',
            user_agreement: '',
            agent_list: {},
            province_url_list: {},
            cms_config: {logo: ''},
            loading: null
        };
    },
    created() {
        let local = new URL(window.location.href)
        let that = this
        axios.get(
            'https://oss.shudaozaixian.com/cache/agent_cms_info/list/' + local.hostname + '.json?time=' + new Date().getTime(), {}
        ).then(function (res) {
            if (res.data.domain) {
                that.agent_id = res.data.domain
                that.agent = res.data.uuid
                let url = 'https://oss.shudaozaixian.com/cache/agent_cms_info/data/' + res.data.domain + '/config.json?time=' + new Date().getTime()
                axios.get(url, {}).then(function (response) {
                    if (response.data) {
                        that.cms_config = response.data
                    }
                }).catch(function (error) {
                });
            }
        }).catch(function (err) {
        });
        
        this.init()
        this.get_oss_list()
    },
    updated() {
        this.init()
    },
    methods: {
        show_huadong() {
            this.$emit('show_huadong_fun')
        },
        init() {
            clearTimeout(this.timer)
            this.timer = null
            this.form = {
                'card_num': '',
                'phone': '',
                'passwd': '',
                'captcha': '',
                'new_password': '',
                'verify_password': '',
            }
            this.user_agreement = localStorage.getItem('user_agreement')
        },
        onSuccess() {
            this.$notify.success("登录成功")
            this.$router.push('/')
        },
        onFail() {
        
        },
        onRefresh() {
        },
        get_oss_list() {
            let that = this
            axios.get(
                api.oss.list + '?t=' + (Date.now() + ''), {}
            ).then(function (res) {
                that.oss_url_list = res.data
                that.get_province_url()
            }).catch(function (err) {
            });
        },
        get_province_url() {
            let that = this
            let province_id = localStorage.getItem('province_id') || 0
            axios.get(
                that.oss_url_list.province_url, {}
            ).then(function (res) {
                that.province_url_list = res.data
                let local_url = that.province_url_list[province_id] || null
                if (local_url !== null) {
                    axios.defaults.baseURL = local_url
                } else if (that.province_url_list[0] !== undefined) {
                    axios.defaults.baseURL = that.province_url_list[0]
                } else {
                    axios.defaults.baseURL = env[process.env.NODE_ENV].API
                }
                
                //console.log(axios.defaults.baseURL)
                
                localStorage.setItem('province_url_list', JSON.stringify(that.province_url_list))
            }).catch(function (err) {
            });
        },
        handleClose() {
            this.loginDialog.show = false
        },
        dialogVisible() {
        },
        show_user_agreement() {
            this.show_remind += 1
            clearTimeout(this.timer)
            this.timer = null
            this.user_entity_btn_txt = "获取验证码";
        },
        show_result(info) {
            if (info.type > 0) {
                this.change_login_type(this.type_list['register'])
            }
        },
        change_login_type(type) {
            this.main_btn_str = this.main_btn_list[type]
            
            type = parseInt(type)
            
            if (type === this.type_list['pass_login'] || type === this.type_list['forgot_pass']) {
                clearTimeout(this.timer)
                this.timer = null
                this.user_entity_btn_txt = "获取验证码"
            }
            
            if (type === this.type_list['weixin']) {
                this.reload_qr_code()
            } else {
                this.type = type
            }
        },
        get_code() {
            this.send_code()
            return false
            
            this.show_check_huadong += 1
            this.show_huadong()
        },
        send_code() {
            if (this.type === this.type_list['card']) {
                this.get_user_entity_phone_code()
            } else if (this.type === this.type_list['register']) {
                this.get_user_register_phone_code()
            } else if (this.type === this.type_list['forgot_pass']) {
                this.get_user_forgot_pass_phone_code()
            } else if (this.type === this.type_list['pass_login']) {
                this.get_user_entity_phone_code()
            }
        },
        login() {
            this.loading = ElLoading.service({
                lock: true,
                text: '加载中...',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            
            if (this.type === this.type_list['card']) {
                this.card_login()
            } else if (this.type === this.type_list['register']) {
                this.phone_register_send()
            } else if (this.type === this.type_list['forgot_pass']) {
                this.forget_password_reset_pass()
            } else if (this.type === this.type_list['pass_login']) {
                this.form.passwd = ''
                this.phone_login()
            } else {
                this.form.captcha = ''
                this.phone_login()
            }
        },
        reload_qr_code() {
            let redirect_uri = env[process.env.NODE_ENV].REDIRECT_URL
            let app_id = env[process.env.NODE_ENV].APP_ID
            let url = 'https://open.weixin.qq.com/connect/qrconnect?appid=' + app_id + '&redirect_uri=' + redirect_uri + '&response_type=code&scope=snsapi_login&state=' + this.agent + '#wechat_redirect'
            window.open(url)
        },
        get_user_forgot_pass_phone_code() {
            this.user_entity_disabled_click = true;
            let reg = /^1\d{10}$/;
            if (reg.test(this.form.phone) === false) {
                this.user_entity_disabled_click = false;
                ElNotification({title: '友情提醒', message: '手机号输入有误', type: 'warning',})
                return false;
            }
            
            this.user_entity_phone_timers()
            
            let that = this
            axios.post(
                api.user.send_phone_code_by_forget_pass, {'phone': this.form.phone, 'agent': this.agent}
            ).then(function (res) {
                //console.log(res.data.data)
                if (res.data.code !== 10000) {
                    that.user_entity_disabled_click = false;
                    ElNotification({title: '友情提醒', message: res.data.data.msg, type: 'warning',})
                }
            }).catch(function (err) {
                ElNotification({title: '友情提醒', message: err.response.data.msg, type: 'warning',})
            });
        },
        get_user_register_phone_code() {
            this.user_entity_disabled_click = true;
            let reg = /^1\d{10}$/;
            if (reg.test(this.form.phone) === false) {
                this.user_entity_disabled_click = false;
                ElNotification({title: '提醒', message: '手机号输入有误', type: 'warning',})
                return false;
            }
            
            let that = this
            axios.post(
                api.user.send_code_by_phone_register, {'phone': this.form.phone, 'agent': this.agent}
            ).then(function (res) {
                //console.log(res.data.data)
                if (res.data.code === 10000) {
                    that.user_entity_phone_timers()
                } else {
                    that.user_entity_disabled_click = false;
                    ElNotification({title: '提醒', message: res.data.data.msg, type: 'warning',})
                }
            }).catch(function (err) {
                that.user_entity_disabled_click = false;
                that.$notify.error({title: '错误', message: err.response.data.msg});
            });
        },
        get_user_entity_phone_code() {
            if (this.user_entity_btn_txt !== '获取验证码') {
                ElNotification({
                    title: '频繁操作',
                    message: '请后' + this.user_entity_btn_txt + '在操作!',
                    type: 'warning',
                    duration: 2000,
                })
                return false
            }
            
            this.user_entity_disabled_click = true;
            let reg = /^1\d{10}$/;
            //console.log(this.form.phone)
            //console.log(reg.test(this.form.phone))
            if (reg.test(this.form.phone) === false) {
                this.user_entity_disabled_click = false;
                ElNotification({title: '友情提醒', message: '手机号输入有误', type: 'warning',})
                return false;
            }
            
            let that = this
            axios.post(
                api.user.send_code_by_phone_code_login, {'phone': this.form.phone, 'agent': that.agent}
            ).then(function (res) {
                //console.log(res.data.data)
                if (res.data.code === 10000) {
                    that.user_entity_phone_timers()
                } else {
                    that.user_entity_disabled_click = false;
                    ElNotification({title: '友情提醒', message: res.data.data.msg, type: 'warning',})
                }
            }).catch(function (err) {
                that.user_entity_disabled_click = false;
                that.$notify.error({title: '错误', message: err.response.data.msg});
            });
            
        },
        user_entity_phone_timers() {
            if (this.user_entity_phone_time > 0) {
                this.user_entity_disabled_click = true;
                this.user_entity_phone_time--;
                this.user_entity_btn_txt = this.user_entity_phone_time + " 秒";
                
                this.timer = setTimeout(this.user_entity_phone_timers, 1000);
            } else {
                this.user_entity_phone_time = 60;
                this.user_entity_btn_txt = "获取验证码";
                this.user_entity_disabled_click = false;
            }
        },
        forget_password_reset_pass() {
            let that = this
            
            let reg_phone = /^1\d{10}$/;
            if (reg_phone.test(this.form.phone) === false) {
                ElNotification({title: '友情提醒', message: '手机号输入有误', type: 'warning',})
                return false;
            }
            
            let reg_code = /^\d{6}$/;
            if (reg_code.test(this.form.captcha) === false) {
                ElNotification({title: '友情提醒', message: '验证码输入有误', type: 'warning',})
                return false;
            }
            
            if (this.form.verify_password.length < 6 || this.form.verify_password.length > 20 || this.form.new_password.length < 6 || this.form.new_password.length > 20) {
                this.user_entity_disabled_click = false;
                ElNotification({title: '友情提醒', message: '密码必须:大于6位,小于20位', type: 'warning',})
                return false;
            }
            
            if (this.form.verify_password !== this.form.new_password) {
                this.user_entity_disabled_click = false;
                ElNotification({title: '友情提醒', message: '两次密码输入有误', type: 'warning',})
                return false;
            }
            
            let info = {...this.form}
            info.agent = this.agent
            info.code = info.captcha
            axios.post(
                api.user.reset_password, info
            ).then(function (res) {
                //console.log(res.data.data)
                if (res.data.code === 10000) {
                    ElNotification({title: '友情提醒', message: res.data.data.msg || '操作成功', type: 'success',})
                    //that.$router.push('/report_list')
                } else {
                    ElNotification({title: '友情提醒', message: res.data.data.msg || '未知错误', type: 'warning',})
                }
                that.loading.close()
            }).catch(function (err) {
                that.loading.close()
                that.$notify.error({title: '错误', message: err.response.data.msg || '未知错误'});
            });
        },
        phone_register_send() {
            let that = this
            let reg_phone = /^1\d{10}$/;
            if (reg_phone.test(this.form.phone) === false) {
                ElNotification({title: '提醒', message: '手机号输入有误', type: 'warning',})
                that.loading.close()
                return false;
            }
            
            let reg_code = /^\d{6}$/;
            if (reg_code.test(this.form.captcha) === false) {
                ElNotification({title: '提醒', message: '验证码输入有误', type: 'warning',})
                that.loading.close()
                return false;
            }
            
            if (this.form.verify_password.length < 6 || this.form.verify_password.length > 20 || this.form.new_password.length < 6 || this.form.new_password.length > 20) {
                this.user_entity_disabled_click = false;
                ElNotification({title: '提醒', message: '密码必须:大于6位,小于20位', type: 'warning',})
                that.loading.close()
                return false;
            }
            
            if (this.form.verify_password !== this.form.new_password) {
                this.user_entity_disabled_click = false;
                ElNotification({title: '提醒', message: '两次密码输入有误', type: 'warning',})
                that.loading.close()
                return false;
            }
            
            let info = {...this.form}
            info.agent = this.agent
            info.code = info.captcha
            axios.post(
                api.user.phone_register, info
            ).then(function (res) {
                if (res.data.code === 10000) {
                    ElNotification({title: '友情提醒', message: res.data.data.msg || '操作成功', type: 'success',})
                    localStorage.setItem('bind_phone', res.data.data.bind_phone)
                    localStorage.setItem('province_id', res.data.data.province_id)
                    localStorage.setItem('login_type', res.data.data.login_type)
                    localStorage.setItem('jwt', res.data.data.token)
                    that.loginDialog.show = false
                    that.base_info = {...entity.base_info}
                    that.$emit('add_base_info')
                } else {
                    ElNotification({title: '友情提醒', message: res.data.data.msg || '未知错误', type: 'warning',})
                }
                that.loading.close()
            }).catch(function (err) {
                that.loading.close()
                that.$notify.error({title: '错误', message: err.response.data.msg || '未知错误'});
            });
        },
        phone_login() {
            let that = this
            axios.post(
                api.user.phone_login, {
                    'phone': this.form.phone,
                    'passwd': this.form.passwd,
                    'captcha': this.form.captcha,
                    'agent': this.agent
                }
            ).then(function (res) {
                if (res.data.code === 10000) {
                    let local_url = that.province_url_list[res.data.data.province_id] || null
                    if (local_url !== null) {
                        axios.defaults.baseURL = local_url
                        localStorage.setItem('local_url', local_url)
                    }
                    localStorage.setItem('bind_phone', res.data.data.bind_phone)
                    localStorage.setItem('province_id', res.data.data.province_id)
                    localStorage.setItem('login_type', res.data.data.login_type)
                    localStorage.setItem('jwt', res.data.data.token)
                    localStorage.setItem('over_time', res.data.data.over_time)
                    that.get_user_info(res.data.data.province_id)
                } else {
                    ElNotification({title: '友情提醒', message: res.data.data.msg, type: 'warning',})
                }
                that.loading.close()
            }).catch(function (err) {
                that.loading.close()
                that.$notify.error({title: '错误', message: err.response.data.msg});
            });
        },
        card_login() {
            let that = this
            axios.post(
                api.user.card_login, {'card_num': this.form.card_num, 'passwd': this.form.passwd, 'agent': this.agent}
            ).then(function (res) {
                //console.log(res)
                if (res.data.code === 10000) {
                    let local_url = that.province_url_list[res.data.data.province_id] || null
                    if (local_url !== null) {
                        localStorage.setItem('local_url', local_url)
                    }
                    
                    localStorage.setItem('bind_phone', res.data.data.bind_phone)
                    localStorage.setItem('province_id', res.data.data.province_id)
                    localStorage.setItem('login_type', res.data.data.login_type)
                    localStorage.setItem('over_time', res.data.data.over_time)
                    localStorage.setItem('jwt', res.data.data.token)
                    
                    // console.log('---card_login---')
                    // console.log(res.data.data)
                    
                    if (res.data.data.bind_phone < 1) {
                        that.$emit('bind_phone')
                        that.loginDialog.show = false
                    } else if (res.data.data.province_id < 1) {
                        //卡号登录 没有绑定手机
                        that.loginDialog.show = false
                        that.base_info = {...entity.base_info}
                        that.$emit('add_base_info')
                    } else {
                        that.get_user_info(res.data.data.province_id)
                    }
                } else {
                    ElNotification({title: '友情提醒', message: res.data.data.msg, type: 'warning',})
                }
                that.loading.close()
            }).catch(function (err) {
                that.loading.close()
                that.$notify.error({title: '错误', message: err.response.data.msg});
            });
        },
        change_info() {
            let base_info = []
            if (this.province_id > 0) {
                this.edit_info = false
            }
            let province_url_list = localStorage.getItem('province_url_list') || null
            if (province_url_list !== null) {
                this.province_url_list = JSON.parse(province_url_list)
                
                let local_url = this.province_url_list[base_info.province_id] || null
                if (local_url !== null) {
                    axios.defaults.baseURL = local_url
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: '信息异常!!!',
                        offset: 50,
                    });
                }
            } else {
                this.$notify.error({
                    title: '错误',
                    message: '信息异常!!!',
                    offset: 50,
                });
            }
            
            let that = this
            base_info.input_precedence = base_info.precedence
            axios.post(
                api.user.input_score, base_info
            ).then(function (res) {
                if (res.data.data.code === 10000) {
                    that.get_user_info()
                    
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
            });
        },
        get_user_info(province_id) {
            let that = this
            let local_url = that.province_url_list[province_id] || null
            if (local_url !== null) {
                localStorage.setItem('local_url', local_url)
                axios.defaults.baseURL = local_url
            }
            
            axios.post(
                api.user.info, {}
            ).then(function (res) {
                // console.log('---get_user_info---')
                // console.log(res.data.data)
                if (res.data.data.province_id === undefined || res.data.data.province_id < 1) {
                    that.loginDialog.show = false
                    that.base_info = {...entity.base_info}
                    that.$emit('add_base_info')
                } else {
                    that.base_info = res.data.data
                    that.province_id = res.data.data.province_id || 0
                    localStorage.setItem('base_info', JSON.stringify(that.base_info))
                    
                    that.$emit('change_province')
                    useInfoStore().needRefresh()
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 580px;
    display: flex;
}

.left {
    width: 460px;
    height: 580px;
    
    img {
        width: 100%;
        height: 100%;
    }
    
    background: linear-gradient(143.36deg, #e93a29 0%, #ef7530 103.25%);
}

.right {
    width: calc(100% - 460px);
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    
    img {
        width: 226px;
        height: 48px;
    }
    
    .edit {
        width: 460px;
        
        .select {
            width: 100%;
            height: 50px;
            border: 1px solid #e1e0e0;
            border-radius: 6px;
            color: var(--font-3);
            margin-bottom: 20px;
            margin-right: 0;
            display: flex;
            align-items: center;
            
            .name {
                width: 90px;
                height: 32px;
                padding: 0 0 0 15px;
                line-height: 32px;
                font-size: 18px;
                border-right: 1px solid #d9d9d9;
            }
            
            .ipt {
                width: calc(100% - 135px);
            }
        }
        
        .ms-button {
            height: 60px;
            line-height: 60px;
            color: #fff;
            margin-top: 30px;
            font-size: 24px;
        }
        
    }
    
    .way {
        width: 100%;
        height: 5px;
        display: flex;
        justify-content: center;
        
        .list {
            width: 80px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
            font-size: 14px;
            color: var(--font-1);
            margin: 0 30px;
            
            img {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.chose_login_type {
    width: 50%;
    cursor: pointer;
    border-bottom: 1px #999999 solid;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
}

.chosed_login_type {
    width: 50%;
    cursor: pointer;
    border-bottom: 3px #e93a29 solid;
    color: #e93a29;
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
}
</style>
