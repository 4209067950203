import axios from "axios"
import env from './env'
import QS from 'qs'

let local_url = localStorage.getItem('local_url') || null
if (local_url === null || local_url === undefined) {
    axios.defaults.baseURL = env[process.env.NODE_ENV].API
} else {
    axios.defaults.baseURL = local_url
}
// 设置超时
// axios.defaults.timeout = 10000
axios.interceptors.request.use(function (config) {
    if (config.url.indexOf('aliyuncs.com') > -1 || config.url.indexOf('oss.shudaozaixian.com') > -1) {
        //
    } else {
        let token = localStorage.getItem("jwt")
        if (token) {
            config.headers.Authorization = token;
        }
    }
    return config;
}, function (err) {
    return Promise.reject(err);
})

export default {
    post(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, QS.stringify(params))
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    get(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params
            }).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.data)
            })
        })
    }
};