<template>
    <el-dialog title="填写个人信息" v-model="edit_info" @close="close_edit_info" :close-on-click-modal="false"
               :close-on-press-escape="false" :show-close="false">
        <el-form :model="base_info" style="margin: 20px;">
            <div class="step_one_content">
                <el-form-item label="姓名" label-width="100px">
                    <el-input v-model="base_info.name" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="高考年份" label-width="100px">
                    <el-select v-model="base_info.grade" placeholder="高考年份" :disabled="this.province_id !== 0">
                        <el-option label="2024" value="2024"></el-option>
                        <el-option label="2025" value="2025"></el-option>
                        <el-option label="2026" value="2026"></el-option>
                        <el-option label="2027" value="2027"></el-option>
                        <el-option label="2028" value="2028"></el-option>
                        <el-option label="2029" value="2029"></el-option>
                        <el-option label="2030" value="2030"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="省份" label-width="100px">
                    <el-select
                        v-model="base_info.province_id"
                        filterable
                        placeholder="所在省份"
                        :disabled="this.province_id !== 0"
                        @change="province_change"
                    >
                        <el-option :label="item.name" v-for="item in provinces" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="详细地址" label-width="100px">
                    <el-select
                        v-model="base_info.city"
                        filterable
                        placeholder="请选择"
                        @change="cities_change"
                        style="margin-right: 10px;width: 180px;">
                        <el-option
                            v-for="(item, index) in chose_cities"
                            :key="index"
                            :label="item"
                            :value="index"
                        >
                        </el-option>
                    </el-select>
                    <el-select
                        v-model="base_info.address"
                        filterable
                        placeholder="请选择"
                        style="width: 180px;">
                        <el-option
                            v-for="(item, index) in chose_areas"
                            :key="index"
                            :label="item"
                            :value="index"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="性别" label-width="100px">
                    <el-radio-group v-model="base_info.gender">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="学校" label-width="100px">
                    <el-input v-model="base_info.school_name" size="small"
                              style="width: 300px;"></el-input>
                </el-form-item>
            </div>
        </el-form>
        <template #footer>
    <span class="dialog-footer">
      <el-button @click="cancel_submit">取消</el-button>
      <el-button type="primary" @click="submit_info">确定</el-button>
    </span>
        </template>
    </el-dialog>
</template>

<script>
import api from '@/api'
import axios from "axios";
import {ElMessageBox} from 'element-plus'
import env from "@/utils/env";

export default {
    name: 'EditInfo',
    props: {
        show: Number,
        fatherMethod: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            edit_info: false,
            base_info: {
                'class_id': 0,
                'grade': (new Date()).getFullYear(),
                'score': 550,
                'classes': 0,
                'name': '',
                'province': '0',
                'province_id': '',
                'subject_one': 0,
                'subject_three': 0,
                'subject_two': 0,
                'subjects': "",
                'majors': [],
                'second': [],
            },
            change: 0,
            oss_list: [],
            gaokaomoshi_url: '',
            areas_url: '',
            subject_three: 0,
            gaokaomoshi: {},
            gaokaomoshi_type: 0,
            gaokaoxuanke_url: '',
            province_url: '',
            gaokaoxuanke_list: {},
            gaokaoxuanke: {},
            province_id: 0,
            major_2nd_limit: 2,
            chose_cities: {},
            chose_areas: {},
            member_info: {},
            all_province: [
                {"name": "北京", "id": 11},
                {"name": "天津", "id": 12},
                {"name": "河北", "id": 13},
                {"name": "山西", "id": 14},
                {"name": "山东", "id": 37},
                {"name": "内蒙古", "id": 15},
                {"name": "辽宁", "id": 21},
                {"name": "吉林", "id": 22},
                {"name": "黑龙江", "id": 23},
                {"name": "上海", "id": 31},
                {"name": "江苏", "id": 32},
                {"name": "浙江", "id": 33},
                {"name": "安徽", "id": 34},
                {"name": "附件", "id": 35},
                {"name": "江西", "id": 36},
                {"name": "河南", "id": 41},
                {"name": "湖北", "id": 42},
                {"name": "湖南", "id": 43},
                {"name": "广东", "id": 44},
                {"name": "广西", "id": 45},
                {"name": "海南", "id": 46},
                {"name": "重启", "id": 50},
                {"name": "四川", "id": 51},
                {"name": "贵州", "id": 52},
                {"name": "云南", "id": 53},
                {"name": "陕西", "id": 61},
                {"name": "甘肃", "id": 62},
                {"name": "青海", "id": 63},
                {"name": "宁夏", "id": 64},
                {"name": "新疆", "id": 65}
            ],
            provinces: [],
        }
    },
    watch: {
        show(new_val) {
            this.edit_info = new_val > 0;
        }
    },
    mounted() {
        this.province_id = parseInt(localStorage.getItem('province_id') || 0)
        this.edit_info = this.show > 0

        let base_info = localStorage.getItem('base_info') || ''
        if (base_info !== null && base_info !== 'null' && base_info !== '[]' && base_info !== '' && base_info !== 'undefined') {
            this.base_info = JSON.parse(base_info)
            this.base_info.grade = this.base_info.classes
            this.base_info.score = parseInt(this.base_info.score)
            this.base_info.city += ''
            this.base_info.province_id = parseInt(this.base_info.province_id)
            this.base_info.second = []
            this.base_info.subject_three = parseInt(this.base_info.subject_three)

            this.set_info_from_storage()
        }else if (this.province_id > 0) {
            this.user_info()
        }
        this.get_oss_list()

        //console.log('--------------------------------------')
        //console.log(this.base_info)
    },
    created() {
    },
    methods: {
        user_info() {
            let that = this
            axios.post(
                api.user.info, {}
            ).then(function (res) {
                that.base_info = res.data.data
                that.base_info.grade = that.base_info.classes
                that.base_info.score = parseInt(that.base_info.score)
                that.base_info.province_id = parseInt(that.base_info.province_id)
                that.base_info.subject_three = parseInt(that.base_info.subject_three)
                that.base_info.second = []
                that.base_info.city += ''
                //console.log(that.base_info)
                localStorage.setItem('base_info', JSON.stringify(res.data.data))

                that.set_info_from_storage()
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        get_areas() {
            let that = this
            axios.get(
                this.areas_url, {}
            ).then(function (res) {
                //console.log('----------get_areas---------------')
                //console.log(res.data)
                that.cities = res.data.cities
                that.areas = res.data.areas
                if (that.base_info.province_id > 0) {
                    that.chose_cities = that.cities[that.base_info.province_id]
                }
                if (that.base_info.city > 0 || that.base_info.city !== '') {
                    that.chose_areas = that.areas[that.base_info.city]
                }
            }).catch(function (err) {
                //console.log(err)
                that.$notify.error({
                    title: '未知错误',
                    message: '未知错误',
                    offset: 50,
                });
            });
        },
        cities_change(v) {
            //console.log(this.areas[v])
            this.chose_areas = this.areas[v]
            this.base_info.address = ''
            this.base_info.city_str = this.chose_cities[v]
        },
        set_info_from_storage() {
            this.base_info.subject_one = parseInt(this.base_info.subject_one)
            this.base_info.subject_two = parseInt(this.base_info.subject_two)
            this.base_info.subject_three = parseInt(this.base_info.subject_three)
            let subject_three = this.base_info.subject_three

            //console.log('-------------------------------------')
            //console.log(this.gaokaomoshi_type)

            if (this.gaokaomoshi_type === 1) {
                this.base_info.second = [this.base_info.subject_two, this.base_info.subject_one]
            } else if (this.gaokaomoshi_type === 2) {
                this.major_2nd_limit = 3
                this.base_info.second = [this.base_info.subject_three, this.base_info.subject_two, this.base_info.subject_one]
            } else  if (this.gaokaomoshi_type === 3) {
                this.base_info.second = []
                // {id: 8, name: "文科"}, {id: 9, name: "理科"}
                if (subject_three === 1) {
                    this.base_info.subject_three = 9
                } else if (subject_three === 2) {
                    this.base_info.subject_three = 8
                }
            }
        },
        get_oss_list() {
            let that = this
            axios.get(
                api.oss.list + '?t=' + (Date.now() + ''), {}
            ).then(function (res) {
                that.gaokaomoshi_url = res.data.gaokaomoshi
                that.gaokaoxuanke_url = res.data.gaokaoxuanke
                that.areas_url = res.data.areas
                that.province_url = res.data.province_url
                that.get_gaokaomoshi()
                that.get_gaokaoxuanke()
                that.get_province_url()
                that.get_areas()
            }).catch(function (err) {
            });
        },
        get_province_url() {
            let province_url_list = localStorage.getItem('province_url_list') || null
            if (province_url_list !== null) {
                this.province_url_list = JSON.parse(province_url_list)
                return false
            }
            let that = this
            axios.get(
                that.province_url, {}
            ).then(function (res) {
                that.province_url_list = res.data
                localStorage.setItem('province_url_list', JSON.stringify(that.province_url_list))
            }).catch(function (err) {
            });
        },
        get_gaokaoxuanke() {
            let that = this
            axios.get(
                that.gaokaoxuanke_url, {}
            ).then(function (res) {
                that.gaokaoxuanke_list = res.data
                if (that.province_id > 0) {
                    that.gaokaoxuanke = that.gaokaoxuanke_list[that.province_id] || {
                        'major_1nd': {},
                        'major_2nd': {},
                        'majors': {},
                    }
                }
            }).catch(function (err) {
            });
        },
        get_gaokaomoshi() {
            let that = this
            axios.get(
                that.gaokaomoshi_url, {}
            ).then(function (res) {
                that.gaokaomoshi = res.data
                localStorage.setItem('gaokaomoshi', JSON.stringify(that.gaokaomoshi))
                for(let i in that.all_province){
                    if(that.gaokaomoshi[that.all_province[i]['id']] !== undefined){
                        that.provinces.push(that.all_province[i])
                    }
                }
                if (that.province_id > 0) {
                    let type = that.gaokaomoshi[that.province_id] || 0
                    that.gaokaomoshi_type = parseInt(type)
                    that.set_info_from_storage()
                }
            }).catch(function (err) {
            });
        },
        province_change(e) {
            let type = this.gaokaomoshi[e] || 0
            this.gaokaoxuanke = this.gaokaoxuanke_list[e] || {'major_1nd': {}, 'major_2nd': {}, 'majors': {},}
            this.gaokaomoshi_type = parseInt(type)
            if (this.gaokaomoshi_type === 1) {
                this.major_2nd_limit = 2
            } else if (this.gaokaomoshi_type === 2) {
                this.major_2nd_limit = 3
            }

            this.chose_cities = this.cities[e]
            this.base_info.city = ''

            this.base_info.second = []
            this.base_info.subject_three = 0
        },
        cancel_submit() {
            if (this.province_id > 0) {
                this.edit_info = false
                this.set_info_from_storage()
            }
        },
        close_edit_info(e) {
            if (this.province_id > 0) {
                this.edit_info = false
            }
        },
        submit_info() {
            if (this.base_info.name.length < 1) {
                this.$notify.error({
                    title: '错误',
                    message: '请填写姓名',
                    offset: 50,
                });
                return false
            }

            if (parseInt(this.base_info.province_id || 0) < 1) {
                this.$notify.error({
                    title: '错误',
                    message: '请选择省份',
                    offset: 50,
                });
                return false
            }
            this.change_info(this.base_info)

            return false

            if (
                (this.gaokaomoshi_type === 1 && this.base_info.second.length !== 2) ||
                (this.gaokaomoshi_type === 2 && this.base_info.second.length !== 3) ||
                (this.gaokaomoshi_type === 3 && this.base_info.subject_three === 0)
            ) {
                this.$notify.error({
                    title: '错误',
                    message: '科目选择有误',
                    offset: 50,
                });
                return false
            }

            if (this.gaokaomoshi_type === 1) {
                this.base_info.subject_two = Math.min(...this.base_info.second)
                this.base_info.subject_one = Math.max(...this.base_info.second)
            } else if (this.gaokaomoshi_type === 2) {
                this.base_info.subject_three = Math.min(...this.base_info.second)
                this.base_info.subject_one = Math.max(...this.base_info.second)

                for (let i in this.base_info.second) {
                    if (this.base_info.second[i] !== this.base_info.subject_three && this.base_info.second[i] !== this.base_info.subject_one) {
                        this.base_info.subject_two = this.base_info.second[i]
                        break
                    }
                }
            } else if (this.gaokaomoshi_type === 3) {
                let majors = this.gaokaoxuanke['majors'][this.base_info.subject_three]
                // {id: 8, name: "文科"}, {id: 9, name: "理科"}
                this.base_info.subject_three = Math.min(...majors)
                this.base_info.subject_one = Math.max(...majors)

                for (let i in majors) {
                    if (majors[i] !== this.base_info.subject_three && majors[i] !== this.base_info.subject_one) {
                        this.base_info.subject_two = majors[i]
                        break
                    }
                }
            } else {
                this.$notify.error({
                    title: '错误',
                    message: '科目选择有误',
                    offset: 50,
                });
                return false
            }

        },

        change_info(base_info) {
            if (this.province_id > 0) {
                this.edit_info = false
            }
            let province_url_list = localStorage.getItem('province_url_list') || null
            if (province_url_list !== null) {
                this.province_url_list = JSON.parse(province_url_list)

                let local_url = this.province_url_list[base_info.province_id] || null
                if (local_url !== null) {
                    axios.defaults.baseURL = local_url
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: '信息异常!!!',
                        offset: 50,
                    });
                }
            } else {
                this.$notify.error({
                    title: '错误',
                    message: '信息异常!!!',
                    offset: 50,
                });
            }

            let that = this
            axios.post(
                api.user.input, base_info
            ).then(function (res) {
                if (res.data.data.code === 10000) {
                    if (res.data.data.province_id) {
                        localStorage.setItem('province_id', res.data.data.province_id)
                        let local_url = that.province_url_list[res.data.data.province_id] || null
                        if (local_url !== null) {
                            localStorage.setItem('local_url', local_url)
                        }
                    }

                    localStorage.removeItem('province_score_gailv');
                    localStorage.removeItem('province_score_chongwenbao');

                    that.edit_info = 0
                    if (res.data.data.token) {
                        //console.log(res.data.data.token)
                        localStorage.setItem('jwt', res.data.data.token)
                        that.province_id = base_info.province_id
                        that.$emit('fatherMethod');
                    } else {
                        that.$emit('fatherMethod');
                    }

                    that.$notify({
                        title: '成功',
                        message: '信息录入成功',
                        type: 'success',
                        offset: 50,
                    });

                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }

                if (that.gaokaomoshi_type === 3) {
                    // {id: 8, name: "文科"}, {id: 9, name: "理科"}
                    if(that.base_info.subject_three === 2){
                        that.base_info.subject_three = 8
                    }else if(that.base_info.subject_three === 1){
                        that.base_info.subject_three = 9
                    }
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
    }
}
</script>
