<template>
    <el-dialog
        v-model="show_bind_phone"
        width="760px"
        class="class-dialog"
        :show-header="false"
        :before-close="handleClose"
    >
        <div class="content_bg">
            <div class="top">绑定手机号</div>
            <div class="center">
                <div class="select">
                    <div class="name">手机号</div>
                    <div class="ipt">
                        <el-input v-model="phone" placeholder="请输入手机号"></el-input>
                    </div>
                </div>
                <div class="select">
                    <div class="name">验证码</div>
                    <div class="ipt">
                        <el-input v-model="captcha" placeholder="请输入验证码">
                            <template #append>
                                <el-button @click="get_phone_code" v-if="times < 1">获取验证码</el-button>
                                <el-button v-else>{{time_status_str}}</el-button>
                            </template>
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="br-button" @click="cancel_tijiao">取消</div>
                <div class="ms-button" @click="send_phone_code">确定</div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import {ElNotification} from "element-plus";
import axios from "axios";
import api from "@/api";
import entity from "@/utils/entity";

export default {
    props: {
        show_bind_phone_status: {
            typeof: Number,
            default: 0,
        },
        change_user_phone: {
            typeof: Function,
            default: null,
        },
    },
    data() {
        return {
            phone: "",
            captcha: "",
            value: "",
            options: "",
            timer: null,
            show_bind_phone: 0,
            times: 0,
            time_status_str: '',
        };
    },
    watch: {
        show_bind_phone_status(num) {
            if (num > 0) {
                this.show_bind_phone += 1
            }
        }
    },
    created() {
        // let bind_phone = localStorage.getItem('bind_phone') || undefined
        // if(bind_phone === '0'){
        //     this.show_bind_phone += 1
        // }
    },
    beforeRouteLeave(){
        localStorage.clear()
    },
    methods: {
        cancel_tijiao() {
            localStorage.clear()
            this.$router.go(0)
        },
        handleClose() {
        },
        dialogVisible() {
        },
        get_phone_code(){
            let that = this
            let reg = /^1\d{10}$/;
            if (reg.test(this.phone) === false) {
                ElNotification({
                    title: '友情提醒',
                    message: "请输入完整手机号",
                    offset: 150,
                })
                return false
            }
            axios.post(
                api.user.get_phone_code, {
                    phone: this.phone
                }
            ).then(function (res) {
                if (res.data.code === 10000) {
                    that.times = 60
                    that.time_status_str = that.times + '秒'
                    that.timer = setInterval(function () {
                        that.times -= 1
                        that.time_status_str = that.times + '秒'
                        if(that.times < 1){
                            that.times = 0
                            clearInterval(that.timer)
                        }
                    }, 1000);
                } else {
                    ElNotification({
                        title: '友情提醒',
                        message: res.data.data.msg || '发送失败!',
                        offset: 150,
                    })
                }
                //console.log(res)
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        send_phone_code() {
            //console.log('send_phone_code')
            let that = this
            let captcha = this.captcha.replace(/\s+/g, '')
            let reg = /^1\d{10}$/;
            if (reg.test(this.phone) === false) {
                ElNotification({
                    title: '友情提醒',
                    message: "请输入完整手机号",
                    offset: 150,
                })
                return false
            }
            if (captcha.length !== 6) {
                ElNotification({
                    title: '友情提醒',
                    message: "请输入完整验证码",
                    offset: 150,
                })
                return false
            }
            axios.post(
                api.user.send_phone_code, {
                    phone: this.phone,
                    captcha: captcha
                }
            ).then(function (res) {
                //console.log(res)
                //console.log(res.data.data)
                //console.log(res.data.data.bind_phone)
                //console.log(res.data.data.province_id)
                //console.log(res.data.data.token)
                if (res.data.code === 10000) {
                    localStorage.setItem('bind_phone', res.data.data.bind_phone)
                    localStorage.setItem('province_id', res.data.data.province_id)
                    localStorage.setItem('jwt', res.data.data.token)
                    
                    that.get_oss_list(res.data.data.province_id)
                    that.show_bind_phone = 0
                    if(res.data.data.province_id < 1){
                        that.$emit('add_base_info')
                    }else{
                        that.$emit('change_user_phone')
                    }
                } else {
                    clearInterval(that.timer);
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        get_oss_list(province_id) {
            let that = this
            axios.get(
                api.oss.list + '?t=' + (Date.now() + ''), {}
            ).then(function (res) {
                that.oss_url_list = res.data
                that.get_province_url(province_id)
            }).catch(function (err) {
            });
        },
        get_province_url(province_id) {
            let that = this
            axios.get(
                that.oss_url_list.province_url, {}
            ).then(function (res) {
                that.province_url_list = res.data
                that.get_user_info(province_id)
            }).catch(function (err) {
            });
        },
        get_user_info(province_id) {
            let that = this
            
            //console.log('-------------------get_user_info--------------------')
            //console.log(that.province_url_list[province_id])
            
            let local_url = that.province_url_list[province_id] || null
            if (local_url !== null) {
                localStorage.setItem('local_url', local_url)
                axios.defaults.baseURL = local_url
            }
            
            axios.post(
                api.user.info, {}
            ).then(function (res) {
                //console.log(res.data)
                that.base_info = res.data.data
                that.province_id = localStorage.getItem('province_id') || 0
                localStorage.setItem('base_info', JSON.stringify(that.base_info))
                
                that.$emit('change_user_phone')
                that.$emit('change_province')
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.content_bg {
    width: 100%;
    height: 300px;
    color: var(--font-1);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../../assets/personal/msg_bg.png');
    
    .top {
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        margin-top: 20px;
    }
    
    .center {
        width: 460px;
        margin-top: 10px;
        margin-bottom: 40px;
    }
    
    .select {
        width: 100%;
        height: 46px;
        margin-right: 0;
        border: 1px solid #e1e0e0;
        border-radius: 6px;
        color: var(--font-3);
        margin-top: 10px;
        display: flex;
        align-items: center;
        
        .name {
            height: 32px;
            width: 72px;
            padding: 0 20px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 3px;
            font-size: 14px;
            border-right: 1px solid #d9d9d9;
        }
        
        .ipt {
            width: calc(100% - 140px);
        }
    }
    
    .bottom {
        display: flex;
        justify-content: center;
        
        .br-button {
            width: 98px;
            height: 34px;
            line-height: 34px;
            font-size: 18px;
            margin-right: 10px;
        }
        
        .ms-button {
            width: 180px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            font-size: 18px;
        }
    }
}
</style>
