module.exports = {
    development: {
        MODE: 'development',
        // API: 'https://api-zhiyuan-t.jiazhiyuan.net/website/v1/',
        API: 'https://api-zhiyuan.jiazhiyuan.net/website/v1/',
        VOCATION_API: 'https://vocational.jiazhiyuan.net/website/v1/',
        PRE_API: 'https://preb.jiazhiyuan.net/website/v1/',
        ART_API: 'https://arts.jiazhiyuan.net/website/v1/',
        JSON_SITE: 'https://ceshi.jiazhiyuan.net/',
        REDIRECT_URL: 'http%3A%2F%2Fwww.jiazhiyuan.net%2F',
        // OSS_URL: 'https://oss.jiazhiyuan.net/',
        OSS_URL: 'https://oss.shudaozaixian.com/',
        APP_ID: 'wx52c7d67a07f46185',
    },
    test: {
        MODE: 'test',
        API: 'https://api-zhiyuan.kboy.fun/website/v1/',
        OSS_URL: 'https://oss.jiazhiyuan.net/',
        APP_ID: 'wx2909e3e9c3ddf0b4',
    },
    production: {
        MODE: 'production',
        API: 'https://api-zhiyuan.jiazhiyuan.net/website/v1/',
        // API: 'https://api-zhiyuan-t.jiazhiyuan.net/website/v1/',
        VOCATION_API: 'https://vocational.jiazhiyuan.net/website/v1/',
        PRE_API: 'https://preb.jiazhiyuan.net/website/v1/',
        ART_API: 'https://arts.jiazhiyuan.net/website/v1/',
        JSON_SITE: 'https://ceshi.jiazhiyuan.net/',
        REDIRECT_URL: 'http%3A%2F%2Fwww.jiazhiyuan.net%2F',
        // OSS_URL:'https://oss.jiazhiyuan.net/',
        OSS_URL: 'https://oss.shudaozaixian.com/',
        APP_ID: 'wx52c7d67a07f46185',
    }
}
