<template>
    <!-- 搜索栏 -->
    <div class="search-box">
        <div class="search-box-info">
            <img :src="config.logo" alt=""/>
            <div class="search">
<!--                <el-input-->
<!--                    v-model="search"-->
<!--                    class="search-ipt"-->
<!--                    placeholder="查大学 / 查专业 / 分数线..."-->
<!--                >-->
<!--                    <template #append>-->
<!--                        <el-icon size="17">-->
<!--                            <Search/>-->
<!--                        </el-icon>-->
<!--                    </template>-->
<!--                </el-input>-->
                <img src="@/assets/layout/Live.png" title="暂未开放"/>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import axios from "axios";

export default {
    data() {
        return {
            search: "",
            config: {
                address: '',
                banner: '',
                bottom_banner: '',
                copyright: '',
                domain: '',
                email: '',
                hotline_phone: '',
                icp: '',
                logo: '',
                name: '',
                phone: '',
                website_title: '',
                wx_mini_url: '',
                wx_offiaccount_url: '',
            }
        };
    },
    beforeRouteLeave(){
        localStorage.clear()
    },
    created() {
        let local = new URL(window.location.href)
        let that = this
        axios.get(
            'https://oss.shudaozaixian.com/cache/agent_cms_info/list/' + local.hostname + '.json?time=' + new Date().getTime(), {}
        ).then(function (res) {
            if (res.data.domain) {
                let url = 'https://oss.shudaozaixian.com/cache/agent_cms_info/data/' + res.data.domain + '/config.json?time=' + new Date().getTime()
                axios.get(url, {}).then(function (response) {
                    if (response.data) {
                        that.config = response.data
                    }
                }).catch(function (error) {
                });
            }
        }).catch(function (err) {
        });
    }
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.search-box {
    width: 100%;
    margin: 10px 0;
    
    .search-box-info {
        height: 60px;
        width: 1230px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        
        img {
            height: 100%;
        }
        
        .search {
            height: 100%;
            display: flex;
            align-items: center;
            
            .search-ipt {
                width: 450px;
                height: 46px;
                border-radius: 4px;
                
                .icon {
                    width: 25px;
                    cursor: pointer;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
            
            img {
                width: 118px;
                height: 46px;
                border-radius: 4px;
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }
    
}
</style>
