<template>
    <TopMsg/>
    <Search/>
    <Tabbar/>
</template>

<script>

import TopMsg from "@/layout/comps/TopMsg.vue";
import Search from "@/layout/comps/Search.vue";
import Tabbar from "@/layout/comps/Tabbar.vue";

export default {
    name: 'Header',
    components: {Tabbar, Search, TopMsg},
    props: {
        chose_index: String,
        show: Number,
        send_collection: {
            type: Function,
            default: null
        },
        father_send_collection: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            activeIndex: '1',
            approval_num: 0,
            site_name: '茗硕',
            oss_sheng_list: {},
            batch_list: {},
        }
    },
    watch: {
        show(new_val) {
            this.get_sheng_oss_list()
        }
    },
    created() {
    },
    methods: {
    },
}
</script>

<style scoped>
</style>
