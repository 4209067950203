<template>
    <el-dialog
        v-model="hollandDialog.show"
        width="760px"
        class="class-dialog"
        :show-header="false"
        :before-close="handleClose"
    >
        <el-icon @click="handleClose" class="close" size="16px" color="#ccc"
        >
            <CloseBold
            />
        </el-icon>
        <div class="content_bg" v-loading="loading">
            <div class="top">
                <div class="name">用户协议</div>
                <div class="progress" style="margin-top: 20px;height:400px;overflow: auto;">
                    <div v-html="contents"></div>
                </div>
            </div>
            <div class="bottom">
                <div class="ms-button" @click="start_test">同意并注册</div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import axios from "axios";
import api from "@/api";

export default {
    props: {
        show_remind: {
            typeof: Number,
            default: {
                return: 0,
            },
        },
        content: {
            typeof: String,
            default: {
                return: '',
            },
        },
        show_result: {
            typeof: Function,
            default: null,
        },
    },
    data() {
        return {
            contents: '',
            loading: false,
            hollandDialog: {show: false},
        };
    },
    watch: {
        show_remind(new_val) {
            if (new_val > 0) {
                this.contents = this.content || ''
                this.get_user_agreement()
                this.hollandDialog.show = true
            }
        }
    },
    methods: {
        get_user_agreement(agent_id) {
            let that = this
            that.loading = true
            let local = new URL(window.location.href)
            axios.get(
                'https://oss.shudaozaixian.com/cache/agent_cms_info/list/' + local.hostname + '.json?time=' + new Date().getTime(), {}
            ).then(function (res) {
                if (res.data.domain) {
                    axios.get(
                        api.oss.list + '?t=' + (Date.now() + ''), {}
                    ).then(function (oss_res) {
                        axios.get(
                            oss_res.data.user_agreements.replace('placeholder', res.data.domain), {}
                        ).then(function (agent_res) {
                            that.contents = agent_res.data || ''
                            that.loading = false
                        }).catch(function (err) {
                            axios.get(
                                oss_res.data.user_agreements.replace('placeholder', 7), {}
                            ).then(function (default_res) {
                                that.contents = default_res.data
                                that.loading = false
                            }).catch(function (err) {
                            });
                        });
                    }).catch(function (err) {
                    });
                }
            }).catch(function (err) {
            });
        },
        handleClose() {
            this.hollandDialog.show = false
            this.$emit('show_result', {'type': 0})
        },
        start_test() {
            this.hollandDialog.show = false
            this.$emit('show_result', {'type': 1})
        }
    },
};
</script>

<style lang="scss" scoped>
.content_bg {
    width: 100%;
    height: 600px;
    color: var(--font-1);
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
        width: 668px;
        margin-top: 50px;

        .name {
            width: 100%;
            text-align: center;
            font-size: 24px;
            font-weight: 700;
        }

        .progress {
            width: 100%;
            margin-top: 50px;

            .font {
                color: var(--font-2);
                font-size: 14px;
                margin: 10px;
            }
        }
    }

    .center {
        width: 668px;
        color: var(--font-1);
        margin-top: 40px;
        margin-bottom: 70px;

        .topic {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .answer {
            display: flex;

            div {
                width: 300px;
                height: 40px;
                line-height: 40px;
                background: var(--main-bg);
                border-radius: 6px;
                padding-left: 20px;
                font-size: 16px;
                margin-right: 20px;
                cursor: pointer;

                &:nth-of-type(2) {
                    margin-right: 0;
                }
            }

            div:hover {
                background-color: #E93A29;
                color: white;
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: center;

        .ms-button {
            position: absolute;
            bottom: 20px;
            width: 180px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            font-size: 18px;
        }
    }
}

.confirm_button_class {
    background-color: #E93A29 !important;
    border: 1px #E93A29 solid !important;
    color: white !important;
}
</style>
