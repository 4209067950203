import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import Print from 'vue3-print-nb'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/index.css'
import '@/style/index.scss'
import VueScrollTo from 'vue-scrollto'
import { createPinia } from 'pinia'

let scrollOptions = {
    container: "body", //滚动的容器
    duration: 500, //滚动时间
    easing: "ease", //缓动类型
    offset: -50, // 滚动时应应用的偏移量。此选项接受回调函数
    force: true, // 是否应执行滚动
    cancelable: true, // 用户是否可以取消滚动
    onStart: false, // 滚动开始时的钩子函数
    onDone: false, // 滚动结束时候的钩子函数
    onCancel: false, // 用户取消滚动的钩子函数
    x: false, // 是否要在x轴上也滚动
    y: true    // 是否要在y轴上滚动
}

//需引入默认样式
document.documentElement.setAttribute('data-theme', 'light');

// 设置标题
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

window._AMapSecurityConfig = {
    securityJsCode: '5895be2baa5c7d21265b84f3800ceb2a'
}

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}

const pinia = createPinia()
const app = createApp(App)
app.use(Print).use(ElementPlus).use(pinia).use(router).use(VueScrollTo,scrollOptions).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
