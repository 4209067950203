import {defineStore} from 'pinia';

export const useInfoStore = defineStore({
    id: 'user_info',
    state: () => ({
        count: 0,
        refresh: 0,
        set_score: 0,
    }),
    actions: {
        increment() {
            this.count++;
        },
        needRefresh() {
            this.refresh++;
        },
        needReSetScore() {
            this.set_score++;
        },
        getRefresh(){
            return this.refresh;
        },
        decrement() {
            this.count--;
        },
    },
});

