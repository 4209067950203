module.exports = {
    base_info: {
        'class_id': 0,
        'score': 0,
        'name': '',
        'city': '',
        'address': '',
        'province': '0',
        'province_id': '0',
        'subject_one': 0,
        'subject_three': 0,
        'subject_two': 0,
        'subjects': [],
        'majors': [],
        'vip_60_need_card': 1,
        'vip_experience_times': 0,
        'vip_level': 0,
        'subjects_name': ['', '', ''],
        'is_vip': 0,
        'can_be_modified': 0,
        'expires_in': '',
        'open_experience_vip': 1
    },
}