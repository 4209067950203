<template>
    <div class="huadong_bg_class" v-if="dialogVisible">
        <div
            style="width: 350px;height:300px;display: flex;justify-content: center;align-items: center;flex-direction: column;background-color: white">
            <div
                style="font-size: 20px;color: #E93A29;margin-bottom: 20px;display: flex;align-items: center;justify-content: space-between;width:100%;">
                <div style="margin-left: 20px">滑动验证</div>
                <div style="margin-right: 20px;cursor: pointer;" @click="dialogVisible = false">
                    <el-icon>
                        <CircleClose/>
                    </el-icon>
                </div>
            </div>
            <slide-verify
                class="silde_box"
                ref="block"
                :slider-text="text"
                :accuracy="accuracy"
                :imgs="imgs"
                @again="onAgain"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
            ></slide-verify>
        </div>
    </div>
</template>

<script>
import SlideVerify, {SlideVerifyInstance} from "vue3-slide-verify";
import "vue3-slide-verify/dist/style.css";
import zhi from '@/assets/zhi.png'
import yuan from '@/assets/yuan.png'

export default {
    data() {
        return {
            dialogVisible: false,
            text: '向右滑动',
            accuracy: 1,
            imgs: [
                zhi, yuan
            ]
        }
    },
    props: {
        show_check_huadong: {
            type: Number,
            default: 0
        },
        check_huadong_success: {
            type: Function,
            default: null
        },
    },
    components: {
        SlideVerify,
    },
    watch: {
        show_check_huadong: {
            handler(val) {
                //console.log('----------new_list---------')
                //console.log(val)
                if (val > 0) {
                    this.dialogVisible = true
                }
            },
            deep: true
        }
    },
    created() {
        //console.log('-------show_check_huadong----------')
    },
    methods: {
        handleClose() {
            this.dialogVisible = false
            //console.log('-------handleClose----')
        },
        onSuccess() {
            this.dialogVisible = false
            this.$emit('check_huadong_success')
        },
        onFail() {
            //console.log('------onFail-----')
        },
        onAgain() {
            //console.log('------onAgain-----')
        },
        onRefresh() {
            //console.log('------onRefresh-----')
        },
    },
};
</script>
<style>

.huadong_bg_class {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>
