<template>
    <!-- 顶部信息 -->
    <div class="topMsg">
        <div class="box">
            <div class="left">
                <div class="left-list" style="cursor: pointer;">
                    <div>当前：</div>
                    <div>{{config.website_title}}</div>
                </div>
                <div class="left-list">
                    <div>全国服务热线：</div>
                    <div>{{config.hotline_phone}}</div>
                </div>
            </div>
            <div class="right">
                <div class="right-list show_xcx">
                    <img src="@/assets/layout/icon1.png" alt="">
                    <div>小程序</div>
                    <div class="xcx_class">
                        <img style="margin: 0 auto;width:100px;height:100px;" :src="config.wx_mini_url" alt=""/>
                    </div>
                </div>
                <div style="width: 50px;"></div>
                <!--                <div class="right-list">-->
                <!--                    <img src="@/assets/layout/icon2.png" alt="">-->
                <!--                    <div>客服</div>-->
                <!--                </div>-->
                <!--                <div class="right-list">-->
                <!--                    <img src="@/assets/layout/icon3.png" alt="">-->
                <!--                    <div>帮助</div>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "@/api";

export default {
    props: {
        province_name: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            config: {
                address: '',
                banner: '',
                bottom_banner: '',
                copyright: '',
                domain: '',
                email: '',
                hotline_phone: '',
                icp: '',
                logo: '',
                name: '',
                phone: '',
                website_title: '',
                wx_mini_url: '',
                wx_offiaccount_url: '',
            }
        }
    },
    created() {
        let local = new URL(window.location.href)
        let that = this
        axios.get(
            'https://oss.shudaozaixian.com/cache/agent_cms_info/list/' + local.hostname + '.json?time=' + new Date().getTime(), {}
        ).then(function (res) {
            if (res.data.domain) {
                let url = 'https://oss.shudaozaixian.com/cache/agent_cms_info/data/' + res.data.domain + '/config.json?time=' + new Date().getTime()
                axios.get(url, {}).then(function (response) {
                    if (response.data) {
                        that.config = response.data
                    }
                }).catch(function (error) {
                });
            }
        }).catch(function (err) {
        });
    }
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.topMsg {
    width: 100%;
    height: 30px;

    .box {
        width: 800px;
        height: 30px;
        float: right;
        background-image: url("../../assets/layout/topMsg.png");
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        line-height: 30px;
        color: #ffffff;

        .left,
        .right {
            display: flex;
            align-content: center;
            padding-right: 10px;

            .left-list {
                display: flex;
                height: 16px;
                line-height: 16px;
                margin-left: 20px;

                div:nth-of-type(1) {
                    color: #ffc2b5;
                }

                &:nth-of-type(1) {
                    padding-right: 20px;
                    border-right: 1px solid #ff8d74;
                }
            }

            .right-list {
                display: flex;
                align-items: center;
                height: 16px;
                line-height: 16px;
                margin-right: 20px;
                padding-right: 20px;
                cursor: pointer;
                //border-right: 1px solid #ff8d74;

                &:nth-of-type(3) {
                    margin-right: 0px;
                    border: 0;
                    padding: 0;
                }

                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 8px;
                }
            }
        }
    }
}

.show_xcx {

}

.show_xcx:hover .xcx_class {
    display: flex;
}

.xcx_class {
    position: absolute;
    top: 35px;
    width: 110px;
    height: 110px;
    background-color: var(--main-bg);
    display: none;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 5px;
    z-index: 999999;
}
</style>