<template>
    <Header chose_index="4"></Header>
    <article class="container">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="院校属性" name="first">
                <el-form ref="form" :model="form" label-width="80px" @submit.native.prevent>
                    <el-form-item label="院校省份">
                        <el-checkbox-group v-model="form.provinces" @change="province_change($event)">
                            <el-checkbox :label="item.province_id" :key="item.id" v-for="item in provinces">
                                {{ item.short }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="院校类型">
                        <el-checkbox-group v-model="form.type" @change="type_change($event)">
                            <el-checkbox :label="item.id" :key="item.id" v-for="item in educational_type">
                                {{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="院校层次">
                        <el-checkbox-group v-model="form.level" @change="level_change($event)">
                            <el-checkbox :label="item.id" :key="item.id" v-for="item in school_level">{{
                                    item.name
                                }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="院校性质">
                        <el-checkbox-group v-model="form.nature_type" @change="nature_type_change($event)">
                            <el-checkbox :label="item.id" :key="item.id" v-for="item in nature_type">{{
                                    item.name
                                }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="招生类型">
                        <el-checkbox-group v-model="form.recruit_type" @change="recruit_type_change($event)">
                            <el-checkbox :label="item.id" :key="item.id" v-for="item in recruit_type">{{
                                    item.name
                                }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="意向院校">
                        <el-select-v2
                            v-model="form.school_ids"
                            filterable
                            :options="school_list"
                            placeholder="请选择"
                            style="min-width: 200px;"
                            @change="school_list_change($event)"
                            clearable
                            multiple
                        />
                    </el-form-item>
                    <div id="" style=";background-color: #FFFFFF;">
                        <el-form-item label="筛选条件"
                                      style="border: #F2F6FC 1px solid;background-color: #FFFFFF;width: 63.4%;"
                                      ref="tiaojian">
                            <span style="width: 10px;"></span>
                            <el-tag v-for="tag in tags" :key="tag.name"
                                    style="color: #333333;background-color: #F2F2F2;border-color:#F2F2F2" closable
                                    @close="tag_click(tag)" :type="tag.type">
                                {{ tag.name }}
                            </el-tag>
                            <el-button type="primary" style="float: right;margin: 6px 5px 0 0;display: none" size="mini"
                                       @click="tags_clear" icon="el-icon-delete">
                                清空
                            </el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="专业属性" name="second">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="专业选择">
                        <el-radio-group v-model="form.major_one" @change="major_one_change">
                            <el-radio :label="item.id" :key="item.id" v-for="item in major_one"
                                      style="margin-top: 10px;margin-bottom: 10px;">
                                {{ item.name }}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <div v-if="majors.length !== 0">
                        <el-divider></el-divider>
                        <el-form-item label="&nbsp;&nbsp;">
                            <el-checkbox-group v-model="form.major_two" @change="major_two_change($event)">
                                <el-checkbox :label="item.code" :key="item.id" v-for="item in majors">
                                    {{ item.name }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-divider></el-divider>
                    </div>
                    <el-form-item label="我的关注">
                        <el-select-v2
                            v-model="form.follow_major"
                            filterable
                            :options="major_three_list"
                            placeholder="请选择"
                            style="min-width: 200px;"
                            @change="major_three_list_change($event)"
                            clearable
                            multiple
                        />
                    </el-form-item>
                    <el-form-item label="筛选条件"
                                  style="border: #F2F6FC 1px solid;background-color: #FFFFFF;width: 63.4%;"
                                  ref="tiaojian2">
                        <span style="width: 10px;"></span>
                        <el-tag v-for="tag in tags" :key="tag.name"
                                style="color: #333333;background-color: #F2F2F2;border-color:#F2F2F2" closable
                                @close="tag_click(tag)" :type="tag.type">
                            {{ tag.name }}
                        </el-tag>
                        <el-button type="primary" style="float: right;margin: 6px 5px 0 0;display: none" size="mini"
                                   @click="tags_clear" icon="el-icon-delete">
                            清空
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <div style="clear: both"></div>
        <div id="top_con">
            <el-tabs type="card" class="demo-tabs" v-model="search_type" @tab-click="chose_change"
                     style="width:240px;height: 50px;line-height: 50px;float:left;">
                <el-tab-pane label="专业/院校" name="major"></el-tab-pane>
                <el-tab-pane label="院校/专业" name="school"></el-tab-pane>
            </el-tabs>
            <div
                style="height: 50px;;background-color: #fff;line-height: 50px;display: flex;flex-wrap:wrap-reverse;justify-content:space-between;align-items:center;border-bottom: 1px #E4E7ED solid;margin-bottom: 40px;">
                <el-button-group>
                    <el-button :type="form.score_range === 5?'primary':''" size="mini" @click="change_status(5)">
                        &nbsp;高风险&nbsp;
                    </el-button>
                    <el-button :type="form.score_range === 1?'primary':''" size="mini" @click="change_status(1)">
                        &nbsp;冲&nbsp;
                    </el-button>
                    <el-button :type="form.score_range === 2?'primary':''" size="mini" @click="change_status(2)">
                        &nbsp;稳&nbsp;
                    </el-button>
                    <el-button :type="form.score_range === 3?'primary':''" size="mini" @click="change_status(3)">
                        &nbsp;保&nbsp;
                    </el-button>
                    <el-button :type="form.score_range === 6?'primary':''" size="mini" @click="change_status(6)">
                        &nbsp;低风险&nbsp;
                    </el-button>
                </el-button-group>
                <el-button-group style="margin-left: 10px;">
                    <el-button :type="form.score_range === 4?'primary':''" size="mini" @click="score_change(1)">高分捡漏
                    </el-button>
                    <el-button :type="form.score_range === 7?'primary':''" size="mini" @click="score_change(2)">低分捡漏
                    </el-button>
                </el-button-group>
                <span>
					<el-button type="text" style="margin-right: 10px;" @click="dialogFormVisible = true">
						<!-- 我的报告 -->
					</el-button>
					<div style="font-size: 12px;float: right">
						我的成绩: {{ base_info.show_score }}分 {{ base_info.precedence }}位次 {{ base_info.subjects }}
						<span @click="edit_info_click" style="cursor:pointer"><i class="el-icon-edit"></i></span>
					</div>
				</span>
            </div>

        </div>
        <div id="top_con2" style="width: 100%;">

        </div>
        <div v-if="2===form.search_type">
            <el-card class="box-card" v-for="item in tableData">
                <div style="height:60px;width:100%;background-color: #F2F2F2;">
                    <div style="position: absolute;" v-if="item.match_status===1" class="major_sprint"></div>
                    <div style="position: absolute;" v-else-if="item.match_status===2" class="major_stable"></div>
                    <div style="position: absolute;" v-else class="major_floors"></div>
                    <div
                        style="position: absolute;height:60px;width:40px;line-height:60px;padding-right:10px;text-align: right;border-radius: 5px;color: white">
                        {{ item.match_str }}
                    </div>
                    <div
                        style="float: left;margin-left:65px;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
            <span style="font-size: 20px;margin-right: 40px;color: #333333">
              {{ item.school_name }}[{{ item.nature_str }}]
            </span>
                        ({{ item.type_str }})[{{ item.postgraduate }}]
                    </div>
                    <div style="float: right;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
                        专业数量({{ item.count }})
                    </div>
                </div>
                <div style="height: 345px;">
                    <div class="school_info">
                        <div style="text-align: center">
                            <img style="height: 160px;" :src="item.school_logo">
                        </div>
                        <div class="recruit_tag" style="text-align: center;margin-top: 10px;margin-left: 40px;">
                            <el-popover
                                placement="top-start"
                                :width="200"
                                trigger="hover"
                                :content="system_tips_list[2]['name']"
                            >
                                <template #reference>
                                    <el-tag size="mini">{{ item.diff }}</el-tag>
                                </template>
                            </el-popover>
                            <el-popover
                                placement="top-start"
                                :width="200"
                                trigger="hover"
                                :content="system_tips_list[3]['name']"
                            >
                                <template #reference>
                                    <el-tag size="mini">{{ item.use_sum }}</el-tag>
                                </template>
                            </el-popover>
                            <el-popover
                                placement="top-start"
                                :width="200"
                                trigger="hover"
                                :content="system_tips_list[1]['name']"
                            >
                                <template #reference>
                                    <el-tag size="mini">{{ item.forecast_low_score }}</el-tag>
                                </template>
                            </el-popover>
                        </div>
                        <div class="school_tag" style="text-align: center;margin-top: 10px;margin-left: 40px;">
                            <el-tag size="mini" type="success" v-for="(label, index) in item.label_list" :key="index">{{
                                    label
                                }}
                            </el-tag>
                        </div>
                    </div>
                    <div class="majors_list">
                        <div style="margin-bottom: 20px;margin-right:20px;"
                             v-for="(major, index) in major_list[item.school_id]">
                            <div>
                                <div>专业</div>
                                <div class="major_info_desc"
                                     :class="major.collection===1?'major_collection':'major_un_collection'">
                  <span :class="major.collection===1?'major_collection':'major_un_collection'"
                        style="float: left;padding-left: 20px;"><span>{{ major.majors }}</span></span>
                                    <span style="float: left;padding-left: 20px;">
                                        <el-tag size="mini" type="danger"
                                                v-if="item.discipline_ranking_major.length > 0">{{
                                                item.discipline_ranking_major
                                            }}</el-tag>
                                        <el-tag size="mini" type="danger" v-if="item.postgraduate_major.length > 0">{{
                                                item.postgraduate_major
                                            }}</el-tag>
                                        <el-tag size="mini" type="danger"
                                                v-if="item.doctor_major.length > 0">{{ item.doctor_major }}</el-tag>
                                    </span>
                                    <span v-if="major.collection === 1"
                                          style="float: right;padding-right: 20px;color: #f8f7f7;cursor: pointer"
                                          @click="un_favorite(index, major, 2)">
                    取消选择
                  </span>
                                    <span v-else>
                    <span v-if="major.major_limit == 1" style="float: right;padding-right: 20px;cursor: pointer"
                          @click="favorite(index, major, 2)">
                      选择
                    </span>
                    <span v-else style="float: right;padding-right: 20px;cursor: pointer;color: #999999">选择</span>
                  </span>
                                </div>
                            </div>
                            <div style="margin-top: 20px;">
                                <div>招生计划</div>
                                <el-row>
                                    <el-col :span="4">
                                        <div class="major_info_desc">
                                            <span>人数</span>&nbsp;|&nbsp;<span>{{ major.num }}</span>
                                        </div>
                                    </el-col>
                                    <el-col :span="4" :offset="1">
                                        <div class="major_info_desc">
                                            <span>学年</span>&nbsp;|&nbsp;<span>{{ major.length }}</span>
                                        </div>
                                    </el-col>
                                    <el-col :span="4" :offset="1">
                                        <div class="major_info_desc">
                                            <span>学费</span>&nbsp;|&nbsp;
                                            <span v-if="item.fees >= 10000" style="color: red;">{{ item.fees }}</span>
                                            <span v-else>{{ item.fees }}</span>
                                        </div>
                                    </el-col>
                                    <el-col :span="8" :offset="1">
                                        <div class="major_info_desc">
                                            <span style="float: left;padding-left: 20px;"><span>选科</span>&nbsp;|&nbsp;
                                            <span>{{ major.major_group_rule }}</span></span>
                                            <span v-if="major.major_limit === 1"
                                                  style="float: right;padding-right: 20px;color: #24C599">选科匹配</span>
                                            <span v-else
                                                  style="float: right;padding-right: 20px;color: #999999">选科不匹配</span>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <div style="margin-top: 20px;">
                                <div>统计 ( 招生人数 / 线差 / 最低分 / 最低位次 )</div>
                                <div class="major_info_desc" style="height: 70px;padding-left: 20px;">
                                    <div class="major_info_desc" style="height: 70px;padding-left: 20px;">
                                        <div style="width: 33%;display: inline-block;text-align: left;margin-top: 5px;"
                                             v-for="(info, year) in major.historical_data">
                                            <div style="line-height: 30px;">{{ year }}</div>
                                            <div style="line-height: 30px;">
                                                {{ info.number_basics }}&nbsp;/&nbsp;
                                                {{ info.line_diff_basics }}&nbsp;/&nbsp;
                                                {{ info.low_score_basics }}&nbsp;/&nbsp;
                                                {{ info.precedence_basics }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: 20px;" v-if="item.remarks.length > 0">
                                <div>备注</div>
                                <div class="major_remarks" style="padding-left: 20px;">
                                    {{ item.remarks }}
                                </div>
                            </div>
                            <el-divider></el-divider>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
        <div v-else>
            <el-card class="box-card" v-for="(item, index) in tableData">
                <div :class="item.collection===1?'collection':'un_collection'">
                    <div style="position: absolute;" v-if="item.match_status===1" class="major_sprint"></div>
                    <div style="position: absolute;" v-else-if="item.match_status===2" class="major_stable"></div>
                    <div style="position: absolute;" v-else class="major_floors"></div>
                    <div
                        style="position: absolute;height:60px;width:40px;line-height:60px;padding-right:10px;text-align: right;border-radius: 5px;color:white">
                        {{ item.match_str }}
                    </div>
                    <div
                        style="float: left;margin-left:65px;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
                        <span :class="item.collection===1?'collection_major':'un_collection_major'">{{
                                item.majors
                            }}</span>
                        <span :class="item.collection===1?'collection_new':'un_collection_new'"
                              v-if="item.new_major === 1">新</span>
                    </div>
                    <div style="float: right;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
            <span v-if="item.collection === 1" style="float: right;padding-right: 20px;color: #F2F2F2;cursor: pointer"
                  @click="un_favorite(index, item, 1)">取消选择</span>
                        <span v-else>
              <span v-if="item.major_limit == 1" style="float: right;padding-right: 20px;cursor: pointer"
                    @click="favorite(index, item, 1)">选择</span>
              <span v-else style="float: right;padding-right: 20px;cursor: pointer;color: #999999">选择</span>
            </span>

                    </div>
                </div>
                <div>
                    <div style="margin-top:30px;margin-bottom:30px;float: left;width: 20%;">
                        <div style="text-align: center">
                            <img style="width:60%" :src="item.school_logo">
                        </div>
                        <div style="text-align: center">
                            {{ item.school_name }}
                        </div>
                        <div style="text-align: center">
                            {{ item.nature_str }}
                        </div>
                    </div>
                    <div style="margin-top:30px;margin-bottom:30px;float: left;width: 20%;">
                        <div class="recruit_tag" style="text-align: left;">
                            <div v-if="item.postgraduate !== ''" style="margin-bottom: 20px;">{{ item.type_str }}
                                [{{ item.postgraduate }}]
                            </div>
                            <div v-else style="margin-bottom: 20px;">{{ item.type_str }}</div>
                            <el-popover
                                placement="top-start"
                                :width="200"
                                trigger="hover"
                                :content="system_tips_list[2]['name']"
                            >
                                <template #reference>
                                    <el-tag size="mini">{{ item.diff }}</el-tag>
                                </template>
                            </el-popover>
                            <el-popover
                                placement="top-start"
                                :width="200"
                                trigger="hover"
                                :content="system_tips_list[3]['name']"
                            >
                                <template #reference>
                                    <el-tag size="mini">{{ item.use_sum }}</el-tag>
                                </template>
                            </el-popover>
                            <el-popover
                                placement="top-start"
                                :width="200"
                                trigger="hover"
                                :content="system_tips_list[1]['name']"
                            >
                                <template #reference>
                                    <el-tag size="mini">{{ item.forecast_low_score }}</el-tag>
                                </template>
                            </el-popover>

                        </div>
                        <div class="school_tag">
                            <el-tag size="mini" type="danger" v-if="item.discipline_ranking_major.length > 0">
                                {{ item.discipline_ranking_major }}
                            </el-tag>
                            <el-tag size="mini" type="danger" v-if="item.postgraduate_major.length > 0">
                                {{ item.postgraduate_major }}
                            </el-tag>
                            <el-tag size="mini" type="danger" v-if="item.doctor_major.length > 0">{{
                                    item.doctor_major
                                }}
                            </el-tag>
                            <el-tag size="mini" type="danger" v-if="item.first_class_major.length > 0">{{
                                    item.first_class_major
                                }}
                            </el-tag>
                        </div>
                        <div class="school_tag">
                            <el-tag size="mini" type="success" v-for="(label, index) in item.label_list" :key="index">
                                {{ label }}
                            </el-tag>
                        </div>
                    </div>
                    <div class="major_list" style="border-left: 1px #EBEBEB solid;">
                        <div>
                            <div>招生计划</div>
                            <el-row>
                                <el-col :span="4">
                                    <div class="major_info_desc">
                                        <span>人数</span>&nbsp;|&nbsp;<span>{{ item.num }}</span>
                                    </div>
                                </el-col>
                                <el-col :span="4" :offset="1">
                                    <div class="major_info_desc">
                                        <span>学年</span>&nbsp;|&nbsp;<span>{{ item.length }}</span>
                                    </div>
                                </el-col>
                                <el-col :span="4" :offset="1">
                                    <div class="major_info_desc">
                                        <span>学费</span>&nbsp;|&nbsp;
                                        <span v-if="item.fees >= 10000" style="color: red;">{{ item.fees }}</span>
                                        <span v-else>{{ item.fees }}</span>
                                    </div>
                                </el-col>
                                <el-col :span="8" :offset="1">
                                    <div class="major_info_desc">
                                        <span style="float: left;padding-left: 20px;">
                                            <span>选科</span>&nbsp;|&nbsp;<span>{{ item.major_group_rule }}</span>
                                        </span>
                                        <span v-if="item.major_limit === 1"
                                              style="float: right;padding-right: 20px;color: #24C599">选科匹配</span>
                                        <span v-else
                                              style="float: right;padding-right: 20px;color: #999999">选科不匹配</span>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div style="margin-top: 20px;">
                            <div>统计 ( 招生人数 / 线差 / 最低分 / 最低位次 )</div>
                            <div class="major_info_desc" style="height: 70px;padding-left: 20px;">
                                <div style="width: 33%;display: inline-block;text-align: left;margin-top: 5px;"
                                     v-for="(info, year) in item.historical_data">
                                    <div style="line-height: 30px;">{{ year }}</div>
                                    <div style="line-height: 30px;">
                                        {{ info.number_basics }}&nbsp;/&nbsp;
                                        {{ info.line_diff_basics }}&nbsp;/&nbsp;
                                        {{ info.low_score_basics }}&nbsp;/&nbsp;
                                        {{ info.precedence_basics }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 20px;" v-if="item.remarks.length > 0">
                            <div>备注</div>
                            <div class="major_remarks" style="padding-left: 20px;">
                                {{ item.remarks }}
                            </div>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
        <div style="height: 20px;"></div>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            v-model:currentPage="form.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="form.page_size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total">
        </el-pagination>
    </article>
    <footer style="background-color: #F8F8F8;width:100%;height:167px;margin-top: 75px;">
        <div style="color: #999999 ;text-align: center;padding-top: 60px;">
            Copyright @ 2011-2021 茗硕择嘉（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>
            备案号：冀ICP备17013465号-1
        </div>
    </footer>
    <el-dialog title="AI智能推荐引擎" v-model="dialogFormVisible" width="50%">
        <div id="myChart" style="margin:0 auto; padding:0;width:600px; height:550px;"></div>
        <template #footer>
    <span class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
    </span>
        </template>
    </el-dialog>
    <EditInfo :show="show_edit_info" @fatherMethod="fatherMethod"></EditInfo>
    <el-dialog
        v-model="need_vip"
        title="该功能需要VIP 是否开通VIP功能"
        :center="true"
        :close-on-press-escape="false"
        :show-close="false"
        width="400px"
        :before-close="handleClose">
        <div style="text-align: center">
            <div>
                <el-button type="primary" @click="open_vip" plain>开通</el-button>
            </div>
        </div>
    </el-dialog>
    <el-dialog
        v-model="favorite_status"
        title="专业特别提示"
        :show-close="false"
        width="30%"
        :before-close="handleClose"
    >
        <div style="font-size: 16px;margin-left: 35px;margin-bottom:15px;" v-for="item in major_remind_list">{{
                item
            }}
        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="favorite_status = false">放弃</el-button>
        <el-button type="primary" @click="continue_favorite">继续选择</el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
import logo from '@/assets/logo.png';
import * as echarts from "echarts";
import http from '@/utils/http'
import api from '@/api'
import EditInfo from "../components/EditInfo";
import Header from "../components/Header";
import axios from "axios";
import env from '@/utils/env'

export default {
    name: 'ChoseSchool',
    components: {Header, EditInfo},
    data() {
        return {
            json_api: env[process.env.NODE_ENV].JSON_SITE,
            search_type: 'major',
            logo: logo,
            favorite_status: false,
            favorite_index: 0,
            favorite_list: [],
            favorite_type: 1,
            major_remind_list: [],
            need_vip: false,
            show_edit_info: 0, // 显示编辑个人信息对话框
            marks: [],
            score: 0,
            all_num: 0,
            top: 0,
            top_num: 0,
            middle_num: 0,
            bottom_num: 0,
            bottom: 0,
            label_num: [],
            dialogVisible: false,
            dialogMajorsVisible: false,
            activeName: 'first',
            listName: 'first',
            tags: {},
            form: {
                provinces: [],
                type: [],
                level: [],
                recruit_type: [],
                nature_type: [],
                school_ids: [],
                follow_major: [],
                major_one: 0,
                major_two: [],
                page_size: 10,
                total: 0,
                page: 1,
                search_type: 1,
                score_range: 2,
            },
            majorsData: [],
            formLabelWidth: '120px',
            dialogFormVisible: false,
            provinces: [],
            types: [],
            schools: [],
            system_tips_list: [],
            major_one: [],
            major_two: [],
            major_three: [],
            major_three_list: [],
            majors: [],
            school_list: [],
            level: [],
            educational_type: [],
            nature_type: [],
            recruit_type: [],
            school_level: [],
            tableData: [],
            base_info: [],
            //
            old_nature_type: [],
            old_recruit_type: [],
            old_educational_type: [],
            old_school_level: [],
            old_provinces: [],
            old_school_list: [],
            old_follow_major: [],
            old_major_two: [],
            major_list: [],
            school_first: false,
            major_first: true,
            tag_index: 0,
        };
    },
    mounted() {
        //this.collect()

        //this.schoolAreas()
        //this.schoolLabel()
        //this.school()
        //this.majorCode()
        //this.user_info()
        this.scrolls()
    },
    created() {
        this.base_info = JSON.parse(localStorage.getItem('base_info'))

        this.get_school_json()
        this.get_school_label_json()
        this.get_province_json()
        this.get_major_json()
        this.system_tips()
        this.search_param()
    },
    methods: {
        handleClose() {
        },
        user_info() {
            let that = this
            axios.post(
                api.user.info, {}
            ).then(function (res) {
                //console.log(res)
                //console.log(res.data.data.status)
                //console.log(res.data.data.base_info)
                if (res.data.data.base_info.score == '0') {
                    that.show_edit_info = 1
                } else if (res.data.data.base_info.vip_level >= 60 &&
                    res.data.data.base_info.need_more_info === 1) {
                    that.show_more_info++
                }

                that.group_id = res.data.data.group_id
                that.group_name = res.data.data.group_name
                that.level_name = res.data.data.level_name
                that.submit_times = res.data.data.base_info.submit_times
                that.subjects_name = res.data.data.base_info.subjects_name
                that.need_more_info = res.data.data.base_info.need_more_info
                that.modify_score_times = parseInt(res.data.data.base_info.modify_score_times)
                that.detailed_info_times = parseInt(res.data.data.base_info.detailed_info_times)
                if (res.data.data.base_info.show_recommend_rank == 1) {
                    that.recommend_ranking = false
                } else {
                    that.recommend_ranking = true
                }
                //console.log('-------------------------------')
                //console.log(that.recommend_ranking)
                that.vip_level = res.data.data.base_info.vip_level
                that.base_info = res.data.data.base_info
                that.user_status = res.data.data.status
                localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                localStorage.setItem('group_name', res.data.data.group_name)
                localStorage.setItem('level_name', res.data.data.level_name)
                localStorage.setItem('submit_times', res.data.data.base_info.submit_times)
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        scrolls() {
            var p = this.$refs.tiaojian.$el
            var p2 = this.$refs.tiaojian2.$el
            //需要固定的元素距离顶部的距离
            var pTop = p.offsetTop
            var pTop2 = p2.offsetTop
            window.onscroll = function () {
                //获取被卷去高度
                var sT = document.documentElement.scrollTop || document.body.scrollsToTop;

                if (sT > pTop) {
                    p.style.position = 'fixed';
                    p.style.top = '0';
                    p.style.zIndex = '999';

                } else {
                    p.style.position = 'static';

                }

                if (sT > pTop2) {
                    p2.style.position = 'fixed';
                    p2.style.top = '0';
                    p2.style.zIndex = '999';

                } else {
                    p2.style.position = 'static';

                }
            }
        },
        get_school_json() {
            let that = this
            axios.get(
                this.json_api + api.school.list_json, {}
            ).then(function (res) {
                //console.log(res)
                that.schools = res.data
                that.school_list = []
                for (let i in that.schools) {
                    that.school_list.push({
                        value: that.schools[i]['id'] + '',
                        label: that.schools[i]['name'] + '',
                        key: that.schools[i]['name'] + '',
                    })
                }
                // that.school_list = Array.from({length: that.schools.length}).map((_, idx) => ({
                //     value: `${that.schools[idx]['id']}`,
                //     label: `${that.schools[idx]['name']}`,
                // }))
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        get_major_json() {
            let that = this
            axios.get(
                this.json_api + api.major.list_json, {}
            ).then(function (res) {
                //console.log(res)
                that.major_one = res.data.level_one
                that.major_two = res.data.level_two
                that.major_three = res.data.level_three
                for (let i in res.data.level_three) {
                    that.major_three_list.push({
                        key: res.data.level_three[i]['id'],
                        value: res.data.level_three[i]['name'],
                        label: res.data.level_three[i]['name'],
                    })
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        get_school_label_json() {
            let that = this
            axios.get(
                this.json_api + api.school.school_label_json, {}
            ).then(function (res) {
                //console.log(res)
                //console.log(res.data)
                that.nature_type = res.data[3]
                for (let i in that.nature_type) {
                    if (that.nature_type[i]['status'] !== 1) {
                        delete that.nature_type[i]
                    }
                }

                that.recruit_type = res.data[13]
                for (let i in that.recruit_type) {
                    if (that.recruit_type[i]['status'] !== 1) {
                        delete that.recruit_type[i]
                    }
                }

                // 院校层次
                that.school_level = res.data[4]
                for (let i in that.school_level) {
                    if (that.school_level[i]['status'] !== 1) {
                        delete that.school_level[i]
                    }
                }

                // 院校类型
                that.educational_type = res.data[9]
                for (let i in that.educational_type) {
                    if (that.educational_type[i]['status'] !== 1) {
                        delete that.educational_type[i]
                    }
                }

            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        get_province_json() {
            let that = this
            axios.get(
                this.json_api + api.province.list_json, {}
            ).then(function (res) {
                that.provinces = res.data
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        open_vip() {
            this.$router.push('/goods')
        },
        chose_change(tab, event) {
            if (tab.props.name === 'major') {
                this.major_button_press()
            } else {
                this.school_button_press()
            }
            this.form.score_change = 0
            this.form.page = 1
        },
        edit_info_click() {
            this.show_edit_info++
        },
        continue_favorite() {
            let index = this.favorite_index
            let list = this.favorite_list
            let type = this.favorite_type

            let data = http.post(api.user.favorite, {"favorite_id": list.id})
            data.then(data => {
                //console.log(data)
                //console.log(data.data)
                if (data.code === 10000) {
                    if (type === 1) {
                        this.tableData[index]['collection'] = 1
                    } else {
                        this.major_list[list['school_id']][index]['collection'] = 1
                    }
                    this.$notify({
                        title: '成功',
                        message: '选择成功',
                        type: 'success',
                        offset: 50,
                    });
                    this.favorite_status = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                    this.favorite_status = false
                }
            })
        },
        favorite(index, list, type = 1) {
            if (list.collection === 1) {
                this.un_favorite(index, list, type)
            } else {
                //console.log(list.major_remind_desc)
                if (list.major_remind_desc !== null && list.major_remind_desc.length > 0) {
                    this.favorite_index = index
                    this.favorite_list = list
                    this.favorite_type = type
                    this.favorite_status = true
                    this.major_remind_list = JSON.parse(list.major_remind_desc)
                } else {
                    this.major_remind_list = []

                    let data = http.post(api.user.favorite, {"favorite_id": list.id})
                    data.then(data => {
                        //console.log(data)
                        //console.log(data.data)
                        if (data.code === 10000) {
                            if (type === 1) {
                                this.tableData[index]['collection'] = 1
                            } else {
                                this.major_list[list['school_id']][index]['collection'] = 1
                            }
                            this.$notify({
                                title: '成功',
                                message: '选择成功',
                                type: 'success',
                                offset: 50,
                            });
                        } else {
                            this.$notify.error({
                                title: '错误',
                                message: data.data.msg,
                                offset: 50,
                            });
                        }
                    })
                }
            }
            //console.log(list.id)
        },
        un_favorite(index, list, type = 1) {
            //console.log(list.id)
            let data = http.post(api.user.un_favorite, {"favorite_id": list.id})
            data.then(data => {
                //console.log(data)
                //console.log(data.data)
                if (data.code === 10000) {
                    if (type === 1) {
                        this.tableData[index]['collection'] = 0
                    } else {
                        this.major_list[list['school_id']][index]['collection'] = 0
                    }
                    this.$notify({
                        title: '成功',
                        message: '取消选择成功',
                        type: 'success',
                        offset: 50,
                    });
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
        show_majors(index, row) {
            this.majorsData = []
            this.dialogMajorsVisible = true;
            let param = this.form;
            param['school_id'] = row.school_id
            param['page'] = 1
            let count = row.count > 10 ? row.count : 10;
            param['page_size'] = count
            let data = http.post(api.match.majors, param)
            data.then(data => {
                this.majorsData = data.data.data
            })
        },
        tags_clear() {
            this.tags = []
        },
        tag_click(tag) {
            //console.log(tag)
            //console.log(this.form)
            for (let i in this.tags) {
                if (this.tags[i]['class'] === tag.class && this.tags[i]['type'] == tag.type) {
                    delete this.tags[i]
                    break
                }
            }

            switch (tag['class']) {
                case 'provinces':
                    for (let i in this.form.provinces) {
                        if (this.form.provinces[i] == tag.type) {
                            this.form.provinces.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'educational_type':
                    for (let i in this.form.type) {
                        if (this.form.type[i] == tag.type) {
                            this.form.type.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'school_level':
                    for (let i in this.form.level) {
                        if (this.form.level[i] == tag.type) {
                            this.form.level.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'major_two':
                    for (let i in this.form.major_two) {
                        if (this.form.major_two[i] == tag.type) {
                            this.form.major_two.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'major_three':
                    for (let i in this.form.follow_major) {
                        if (this.form.follow_major[i] == tag.type) {
                            this.form.follow_major.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'school_list':
                    for (let i in this.form.school_ids) {
                        if (this.form.school_ids[i] == tag.type) {
                            this.form.school_ids.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'nature_type':
                    for (let i in this.form.nature_type) {
                        if (this.form.nature_type[i] == tag.type) {
                            this.form.nature_type.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'recruit_type':
                    for (let i in this.form.recruit_type) {
                        if (this.form.recruit_type[i] == tag.type) {
                            this.form.recruit_type.splice(i, 1);
                            break
                        }
                    }
                    break;
                default:
                    //console.log(tag)
                    break
            }

            this.change()
        },
        province_change(e) {
            //console.log(e)
            let new_obj = e.concat(this.old_provinces).filter(v => e.includes(v) && !this.old_provinces.includes(v))
            //console.log(new_obj)
            if (new_obj.length > 0) {
                this.tag_index++
                let new_index = new_obj[0]
                this.tags[this.tag_index] = {
                    name: this.provinces[new_index]['short'],
                    type: this.provinces[new_index]['province_id'],
                    class: 'provinces'
                }
            }

            let del_obj = this.old_provinces.concat(e).filter(v => !e.includes(v) && this.old_provinces.includes(v))
            //console.log(del_obj)
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'provinces' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_provinces = e

            this.change()
        },
        delete_tag(tag_name, class_name, type_id) {
            for (let i in this.tags) {
                if (this.tags[i]['name'] === tag_name && this.tags[i]['class'] === class_name && parseInt(this.tags[i]['type']) === parseInt(type_id)) {
                    delete this.tags[i]
                    break
                }
            }
        },
        type_change(e) {
            ////console.log(e)
            let new_obj = e.concat(this.old_educational_type).filter(v => e.includes(v) && !this.old_educational_type.includes(v))
            if (new_obj.length > 0) {
                this.tag_index++
                let new_index = new_obj[0]
                this.tags[this.tag_index] = {
                    name: this.educational_type[new_index]['name'],
                    type: this.educational_type[new_index]['id'],
                    class: 'educational_type'
                }
            }

            let del_obj = this.old_educational_type.concat(e).filter(v => !e.includes(v) && this.old_educational_type.includes(v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'educational_type' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_educational_type = e

            this.change()
        },
        nature_type_change(e) {
            ////console.log(e)
            let new_obj = e.concat(this.old_nature_type).filter(v => e.includes(v) && !this.old_nature_type.includes(v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                this.tag_index++
                this.tags[this.tag_index] = {
                    name: this.nature_type[new_index]['name'],
                    type: this.nature_type[new_index]['id'],
                    class: 'nature_type'
                }
            }

            let del_obj = this.old_nature_type.concat(e).filter(v => !e.includes(v) && this.old_nature_type.includes(v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'nature_type' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_nature_type = e

            this.change()
        },
        recruit_type_change(e) {
            let new_obj = e.concat(this.old_recruit_type).filter(v => e.includes(v) && !this.old_recruit_type.includes(v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                this.tag_index++
                this.tags[new_index] = {
                    name: this.recruit_type[new_index]['name'],
                    type: this.recruit_type[new_index]['id'],
                    class: 'recruit_type'
                }
            }

            let del_obj = this.old_recruit_type.concat(e).filter(v => !e.includes(v) && this.old_recruit_type.includes(v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'recruit_type' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_recruit_type = e

            this.change()
        },
        level_change(e) {
            let new_obj = e.concat(this.old_school_level).filter(v => e.includes(v) && !this.old_school_level.includes(v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                this.tag_index++
                this.tags[this.tag_index] = {
                    name: this.school_level[new_index]['name'],
                    type: this.school_level[new_index]['id'],
                    class: 'school_level'
                }
            }

            let del_obj = this.old_school_level.concat(e).filter(v => !e.includes(v) && this.old_school_level.includes(v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'school_level' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_school_level = e

            this.change()
        },
        major_two_change(e) {
            //console.log(e)
            let new_obj = e.concat(this.old_major_two).filter(v => e.includes(v) && !this.old_major_two.includes(v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                for (let i in this.major_two[this.form.major_one]) {
                    if (new_index == this.major_two[this.form.major_one][i]['code']) {
                        this.tag_index++
                        this.tags[this.tag_index] = {
                            name: this.major_two[this.form.major_one][i]['name'],
                            type: this.major_two[this.form.major_one][i]['code'],
                            class: 'major_two'
                        }
                    }
                }
            }

            let del_obj = this.old_major_two.concat(e).filter(v => !e.includes(v) && this.old_major_two.includes(v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'major_two' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_major_two = e

            this.change()
        },
        major_three_list_change(e) {
            //console.log(e)
            let new_obj = e.concat(this.old_follow_major).filter(v => e.includes(v) && !this.old_follow_major.includes(v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                for (let i in this.major_three_list) {
                    if (new_index == this.major_three_list[i]['value']) {
                        this.tag_index++
                        this.tags[new_index] = {
                            name: this.major_three_list[i]['label'],
                            type: this.major_three_list[i]['value'],
                            class: 'major_three'
                        }
                    }
                }
            }

            let del_obj = this.old_follow_major.concat(e).filter(v => !e.includes(v) && this.old_follow_major.includes(v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'major_three' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_follow_major = e

            this.change()
        },
        school_list_change(e) {
            //console.log(e)
            //console.log(this.form.school_ids)
            let new_obj = e.concat(this.old_school_list).filter(v => e.includes(v) && !this.old_school_list.includes(v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                for (let i in this.school_list) {
                    if (new_index == this.school_list[i]['value']) {
                        this.tag_index++
                        this.tags[this.tag_index] = {
                            name: this.school_list[i]['label'],
                            type: this.school_list[i]['value'],
                            class: 'school_list'
                        }
                    }
                }
            }

            let del_obj = this.old_school_list.concat(e).filter(v => !e.includes(v) && this.old_school_list.includes(v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'school_list' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_school_list = e

            this.change()
        },
        change_status(val) {
            this.form.score_range = val
            this.form.score_change = 0
            this.form.page = 1
            this.change()
        },
        score_change(val = 1) {
            if (val === 1) {
                this.form.score_range = 4
            } else {
                this.form.score_range = 7
            }
            this.form.score_change = val
            this.form.page = 1
            this.change()
        },
        search_param() {
            let that = this
            let data = http.get(api.user.search_param, {})
            data.then(data => {
                //console.log(data.data)
                if (data.data['param'] != undefined) {
                    that.form.school_ids = data.data['param']['school_ids'] || []
                    for (let i in that.form.school_ids) {
                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.schools[that.form.school_ids[i]]['name'],
                            type: that.schools[that.form.school_ids[i]]['id'],
                            class: 'school_list'
                        }
                    }

                    that.form.type = []
                    for (let i in data.data['param']['type']) {
                        let p = parseInt(data.data['param']['type'][i])
                        that.form.type.push(p)

                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.educational_type[p]['name'],
                            type: that.educational_type[p]['id'],
                            class: 'educational_type'
                        }
                    }

                    that.form.level = []
                    for (let i in data.data['param']['level']) {
                        let p = parseInt(data.data['param']['level'][i])
                        that.form.level.push(p)

                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.school_level[p]['name'],
                            type: that.school_level[p]['id'],
                            class: 'school_level'
                        }
                    }

                    that.form.recruit_type = []
                    for (let i in data.data['param']['recruit_type']) {
                        let p = parseInt(data.data['param']['recruit_type'][i])
                        that.form.recruit_type.push(p)

                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.recruit_type[p]['name'],
                            type: that.recruit_type[p]['id'],
                            class: 'recruit_type'
                        }
                    }

                    that.form.nature_type = []
                    for (let i in data.data['param']['nature_type']) {
                        let p = parseInt(data.data['param']['nature_type'][i])
                        that.form.nature_type.push(p)

                        that.tag_index++
                        this.tags[that.tag_index] = {
                            name: that.nature_type[p]['name'],
                            type: that.nature_type[p]['id'],
                            class: 'nature_type'
                        }
                    }

                    that.form.provinces = []
                    that.old_provinces = []
                    for (let i in data.data['param']['provinces']) {
                        let p = parseInt(data.data['param']['provinces'][i])
                        that.form.provinces.push(p)
                        that.old_provinces.push(p)

                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.provinces[p]['short'],
                            type: that.provinces[p]['province_id'],
                            class: 'provinces'
                        }

                    }

                    that.form.major_one = parseInt(data.data['param']['major_one']) || 0
                    if (that.form.major_one == 0) {
                        that.form.major_one = parseInt(data.data['param']['level_one']) || 0
                    }

                    let major_two_code = ''
                    for (let i in that.major_one) {
                        if (that.major_one[i]['id'] == that.form.major_one) {
                            major_two_code = that.major_one[i]['code']
                        }
                    }
                    that.majors = []
                    for (let i in that.major_two) {
                        if (that.major_two[i]['level1_id'] == that.form.major_one) {
                            that.majors.push(that.major_two[i])
                        }
                    }

                    that.form.major_two = []
                    for (let i in data.data['param']['major_two']) {
                        that.form.major_two.push(i)
                    }

                    that.form.follow_major = []
                    for (let i in data.data['param']['follow_major']) {
                        that.form.follow_major.push(i)

                        for (let j in this.major_three_list) {
                            if (i == this.major_three_list[j]['value']) {
                                that.tag_index++
                                that.tags[that.tag_index] = {
                                    name: that.major_three_list[j]['label'],
                                    type: that.major_three_list[j]['value'],
                                    class: 'major_three'
                                }
                            }
                        }
                    }
                }

                that.change()
            })
        },
        system_tips() {
            let that = this
            let data = http.get(api.system_tips.list, {page: 1, step: 10, show_all: 1})
            data.then(data => {
                //console.log(data.data)
                that.system_tips_list = data.data
            })
        },
        change() {
            let obj = {...this.form}

            let data = http.post(api.match.list, obj)
            data.then(data => {
                //console.log(data)
                this.tableData = data.data.data
                this.form.page = data.data.current_page
                this.form.total = data.data.total
                this.form.page_size = data.data.per_page
                this.major_list = data.data.majors
            })
        },
        major_one_change(e) {
            this.majors = []
            for (let i in this.major_two) {
                if (this.major_two[i]['level1_id'] == e) {
                    this.majors.push(this.major_two[i])
                }
            }
        },
        school_input_change(event) {
            //console.log(this.form.school_ids)
        },
        handleSizeChange(val) {
            this.form.page_size = val
            this.form.page = 1
            this.change()
            //console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.form.page = val
            this.change()
            //console.log(`当前页: ${val}`);
        },
        //行拖拽
        rowDrop() {
            const tbody = document.querySelector('.el-table__body-wrapper tbody')
            const _this = this
            Sortable.create(tbody, {
                onEnd({newIndex, oldIndex}) {
                    const currRow = _this.tableData.splice(oldIndex, 1)[0]
                    _this.tableData.splice(newIndex, 0, currRow)
                }
            })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        clearFilter() {
            this.$refs.filterTable.clearFilter();
        },
        tableRowClassName({row, rowIndex}) {
            if (rowIndex === 1) {
                return 'warning-row';
            } else if (rowIndex === 3) {
                return 'success-row';
            }
            return '';
        },
        handleClick(tab, event) {
            // //console.log(tab, event);
        },
        listHandleClick(tab, event) {
            ////console.log(tab, event);
            if (tab.props.name === '') {
                return false;
            }
        },
        onSubmit() {
            //console.log('submit!');
        },
        schoolAreas() {
            let data = http.get(api.school.area, {})
            data.then(data => {
                //console.log(data.data)
                this.provinces = data.data

                for (let i in this.provinces) {
                    if (this.provinces[i]['isShow'] == 1) {
                        this.tags[i] = {
                            name: this.provinces[i]['province'],
                            type: this.provinces[i]['province_id'],
                            class: 'provinces'
                        }
                    }
                }
            })
        },
        schoolLabel() {
            let data = http.get(api.school.label, {})
            data.then(data => {
                //console.log(data.data)
                //this.educational_type = data.data.educational_type

                //this.recruit_type = data.data.recruit_type
                //this.school_level = data.data.school_level
                this.level = data.data.school_level
                this.types = data.data.recruit_type

                for (let i in this.educational_type) {
                    if (this.educational_type[i]['isShow'] == 1) {
                        this.tags[i] = {
                            name: this.educational_type[i]['name'],
                            type: this.educational_type[i]['id'],
                            class: 'educational_type'
                        }
                    }
                }

                for (let i in this.level) {
                    if (this.level[i]['isShow'] == 1) {
                        this.tags[i] = {
                            name: this.level[i]['name'],
                            type: this.level[i]['id'],
                            class: 'school_level'
                        }
                    }
                }

                for (let i in this.nature_type) {
                    if (this.nature_type[i]['isShow'] == 1) {
                        this.tags[i] = {
                            name: this.nature_type[i]['name'],
                            type: this.nature_type[i]['id'],
                            class: 'nature_type'
                        }
                    }
                }

                for (let i in this.recruit_type) {
                    if (this.recruit_type[i]['isShow'] == 1) {
                        this.tags[i] = {
                            name: this.recruit_type[i]['name'],
                            type: this.recruit_type[i]['id'],
                            class: 'recruit_type'
                        }
                    }
                }

            })
        },
        schoolType() {
            let data = http.get(api.school.type, {})
            data.then(data => {
                this.types = data.data
            })
        },
        schoolLevel() {
            let that = this
            let data = http.get(api.school.level, {})
            data.then(data => {
                that.level = data.data
            })
        },
        school() {
            let that = this
            let data = http.get(api.school.list, {})
            data.then(data => {
                //console.log(data.data)
                that.schools = data.data
                that.school_list = Array.from({length: that.schools.length}).map((_, idx) => ({
                    value: `${that.schools[idx]['id']}`,
                    label: `${that.schools[idx]['name']}`,
                }))

                for (let i in that.schools) {
                    if (that.schools[i]['isShow'] == 1) {
                        that.tags[i] = {
                            name: this.schools[i]['name'],
                            type: this.schools[i]['id'],
                            class: 'school_list'
                        }
                    }
                }
            })
        },
        majorCode() {
            let that = this
            let data = http.get(api.major.list, {})
            data.then(data => {
                //console.log(data)
                that.major_one = data.data.level_one
                that.major_two = data.data.level_two
                that.major_three = data.data.level_three
                that.major_three_list = Array.from({length: that.major_three.length}).map((_, idx) => ({
                    key: `${that.major_three[idx]['id']}`,
                    value: `${that.major_three[idx]['code']}`,
                    label: `${that.major_three[idx]['name']}`,
                }))

                if (that.form.major_one > 0) {
                    that.majors = that.major_two[that.form.major_one]
                }
                if (that.form.level_one > 0) {
                    that.majors = that.major_two[that.form.level_one]
                }

                for (let i in that.major_two) {
                    for (let j in that.major_two[i]) {
                        if (that.major_two[i][j]['isShow'] == 1) {
                            that.tags[i] = {
                                name: that.major_two[i][j]['name'],
                                type: that.major_two[i][j]['code'],
                                class: 'major_two'
                            }
                        }
                    }
                }

                for (let i in that.major_three) {
                    if (that.major_three[i]['isShow']) {
                        this.tags[i] = {
                            name: that.major_three[i]['name'],
                            type: that.major_three[i]['code'],
                            class: 'major_three'
                        }
                    }
                }

            })
        },
        collect() {
            let data = http.get(api.algorithm.collect, {})
            data.then(data => {
                this.score = data.data.score
                this.all_num = data.data.all_num
                this.top_num = data.data.top_num
                this.top = data.data.top
                this.middle_num = data.data.middle_num
                this.bottom_num = data.data.bottom_num
                this.bottom = data.data.bottom
                this.label_num = data.data.label_num

                let score = data.data.score
                let top = data.data.top
                let bottom = data.data.bottom

                this.marks = {
                    top: top + '',
                    score: {
                        style: {
                            color: '#1989FA'
                        },
                        label: score + ''
                    },
                    bottom: bottom + '',
                }

                let legend_data = Object.keys(this.label_num);
                let series_data = [
                    {value: this.top_num, name: '冲刺'},
                    {value: this.middle_num, name: '稳妥', selected: true},
                    {value: this.bottom_num, name: '保底'}
                ]
                let info = []
                for (let i in this.label_num) {
                    info.push({'name': i, 'value': this.label_num[i]})
                }

                let label_num_data = info
                let dom = document.getElementById('myChart')

                if (dom != null) {
                    let myChart = echarts.init(dom);
                    // 绘制图表
                    myChart.setOption({
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/>{b}: {c} ({d}%)'
                        },
                        legend: {
                            data: legend_data
                        },
                        series: [
                            {
                                name: '统计',
                                type: 'pie',
                                selectedMode: 'single',
                                radius: [0, '30%'],
                                label: {
                                    formatter: '{b}: {c}',
                                    position: 'inner',
                                    fontSize: 14,
                                },
                                labelLine: {
                                    show: false
                                },
                                data: series_data
                            },
                            {
                                name: '学校数量',
                                type: 'pie',
                                radius: ['45%', '60%'],
                                labelLine: {
                                    length: 30,
                                },
                                label: {
                                    formatter: ' {b|{b}}:{c} ',
                                    backgroundColor: '#F6F8FC',
                                    borderColor: '#8C8D8E',
                                    borderWidth: 1,
                                    borderRadius: 4,

                                    rich: {
                                        a: {
                                            color: '#6E7079',
                                            lineHeight: 22,
                                            align: 'center'
                                        },
                                        hr: {
                                            borderColor: '#8C8D8E',
                                            width: '100%',
                                            borderWidth: 1,
                                            height: 0
                                        },
                                        b: {
                                            color: '#4C5058',
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            lineHeight: 33
                                        },
                                        per: {
                                            color: '#fff',
                                            backgroundColor: '#4C5058',
                                            padding: [3, 4],
                                            borderRadius: 4
                                        }
                                    }
                                },
                                data: label_num_data
                            }
                        ]
                    });
                    window.onresize = () => {
                        myChart.resize()
                    }
                }
            })
        },
        school_button_press(e) {
            this.form.search_type = 2
            this.search_type = 'school'
            this.school_first = true
            this.major_first = false
            this.change()
        },
        major_button_press(e) {
            this.form.search_type = 1
            this.search_type = 'major'
            this.school_first = false
            this.major_first = true
            this.change()
        },
        sign_out() {
            localStorage.clear()
            this.$router.push('/')
        }
    }
}
</script>
<style scoped="scoped">
* {
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}

.container {
    background-color: #F5F4F8;
    width: 64%;
    margin: 0 auto;
}

#top_con {
    position: sticky;
    top: 26px;
    background-color: #ffffff;
    z-index: 99;

}

#top_con2 {
    position: sticky;
    top: 76px;
    border: 1px solid #FFFFFF;
    background-color: #ffffff;
    z-index: 99;
    box-shadow: 0px 4px 5px #000000;

}


.box-card {
    border: 0;
    margin-top: 15px;
}

.box-card >>> .el-card__body {
    padding: 0;
}

.box-card >>> .el-card__header {
    padding: 0;
}

.major_sprint {
    border-top: 60px solid #F3AB50;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_floors {
    border-top: 60px solid #24C599;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_stable {
    border-top: 60px solid #4D94F1;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_info_desc {
    background-color: #F8F8F8;
    border-radius: 5px;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
}

.major_remarks {
    background-color: #F8F8F8;
    border-radius: 5px;
    margin-top: 10px;
    min-height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 12px;
}

.school_info {
    margin-top: 30px;
    float: left;
    height: 285px;
    width: 40%;
    border-right: 1px #EBEBEB solid;
}

.majors_list {
    float: right;
    margin-top: 30px;
    height: 285px;
    width: 57%;
    overflow-y: scroll;
    scrollbar-color: #DBDBDB #F8F8F8;
    /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin;
    /* 滚动条宽度有三种：thin、auto、none */
}

.major_list {
    float: left;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 20px;
    width: 57%;
    height: 210px;
    overflow-y: auto;
}

/*.majors_list::-webkit-scrollbar {*/
/*    !*滚动条整体样式*!*/
/*    width: 5px; !*高宽分别对应横竖滚动条的尺寸*!*/
/*    height: 1px;*/
/*}*/

/*.majors_list::-webkit-scrollbar-thumb {*/
/*    !*滚动条里面小方块*!*/
/*    border-radius: 10px;*/
/*    background-color: skyblue;*/
/*    background-image: -webkit-linear-gradient(#e70909, #DBDBDB);*/
/*}*/

/*.majors_list::-webkit-scrollbar-track {*/
/*    !*滚动条里面轨道*!*/
/*    box-shadow: inset 0 0 5px rgba(248, 248, 248, 1);*/
/*    background: #000000;*/
/*    border-radius: 10px;*/
/*}*/

.school_tag {
    text-align: left;
    margin-top: 10px;
}

.school_tag >>> .el-tag--light {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 5px;
}

.recruit_tag >>> .el-tag--light {
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 5px;
}


.el-checkbox >>> .el-checkbox__input {
    display: none;
}

.el-form-item >>> .el-form-item__label {
    line-height: 30px;
}

.el-radio-group >>> .el-radio__label {
    line-height: 30px;
}

.el-checkbox-group >>> .is-checked {
    background-color: #E93A29;
    border-radius: 4px;
}

.el-radio-group >>> .is-checked {
    background-color: #E93A29;
    border-radius: 4px;
}

.el-radio-group >>> .el-radio__label {
    color: #999999;
    padding: 8px;
    text-align: center;
}

.el-radio-group >>> .is-checked .el-radio__label {
    color: #FFFFFF;
    padding: 8px;
    text-align: center;
}

.el-checkbox-group >>> .el-checkbox__label {
    color: #999999;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
}

.el-checkbox-group >>> .is-checked .el-checkbox__label {
    color: #FFFFFF;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
}

.el-form-item >>> .el-form-item__content {
    line-height: 30px;
}

.el-radio >>> .el-radio__input {
    display: none;
}

.el-tag >>> .el-tag__close {
    color: #333333
}

.selected {
    color: #d01414;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    cursor: pointer
}

.unselected {
    color: #919090;
    font-size: 20px;
    cursor: pointer
}

.collection {
    height: 60px;
    width: 100%;
    background-color: #E93A29;
}

.un_collection {
    height: 60px;
    width: 100%;
    background-color: #F2F2F2;
}

.major_collection {
    background-color: #E93A29;
    color: #F2F2F2;
}

.major_un_collection {
    background-color: #F2F2F2;
}

.collection_new {
    color: #F2F2F2;
    font-size: 16px;
}

.un_collection_new {
    color: #e8320a;
    font-size: 16px;
}

.collection_major {
    font-size: 20px;
    margin-right: 10px;
    color: #F2F2F2;
}

.un_collection_major {
    font-size: 20px;
    margin-right: 10px;
    color: #333333;
}

a {
    color: #666666;
    text-decoration: none;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

a:focus {
    text-decoration: none;
}
</style>

