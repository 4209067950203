<template>
    <div class="app-wrapper">
        <TopMsg/>
        <Search/>
        <Tabbar :show_login="show_login" :show_bind_phone="show_bind_phone" @add_base_info="add_base_info"
                @change_province="change_province"/>
        <AppMain
            :add_base_info_status="add_base_info_status"
            @change_menu_action="change_menu_action"
            :change_province_status="change_province_status"
            @reset_base_info_after_register="reset_base_info_after_register"
            @change_bind_phone="change_bind_phone"/>
        <BtmMsg/>
    </div>
</template>

<script>
import {AppMain, TopMsg, Search, Tabbar, BtmMsg} from "./comps";

export default {
    components: {
        AppMain,
        TopMsg,
        Search,
        Tabbar,
        BtmMsg,
    },
    data() {
        return {
            show_login: 0,
            show_user_info: 0,
            change_province_status: 0,
            show_bind_phone: 0,
            add_base_info_status: 0,
            province_name: ''
        }
    },
    beforeRouteLeave() {
        localStorage.clear()
    },
    methods: {
        reset_base_info_after_register() {
            this.show_user_info += 1
        },
        change_menu_action(info) {
            //console.log('------------------------change_menu_action(info)--------------------')
            //console.log(info.type)
            if (info.type === 'show_login') {
                this.show_login += 1
            }else{
                this.show_login = -1
            }
        },
        add_base_info() {
            //console.log('----add_base_info--------')
            this.show_login = 0
            //console.log(this.show_login)
            this.add_base_info_status += 1
        },
        change_province(info) {
            this.change_province_status += 1
        },
        change_bind_phone(info) {
            //console.log('-------------change_bind_phone--------------------')
            //console.log(info)
            this.show_bind_phone += 1
        }
    }
};
</script>

<style lang="scss" scoped>
.app-wrapper {
    width: 100%;
    font-size: 14px;
    overflow: hidden;
}
</style>