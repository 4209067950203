<template>
    <div class="btmMsg">
        <div class="info_model">
            <div class="banner" v-if="isBanner">
                <img :src="config.bottom_banner" alt="">
            </div>
            <div class="msg_info">
                <div class="msg">
                    <div class="list">
                        <div class="title">联系我们</div>
                        <div class="cont">{{config.phone}}&nbsp;</div>
                        <div class="cont">{{config.email}}&nbsp;</div>
                    </div>
                    <div class="list">
                        <div class="title">公司地址</div>
                        <div class="cont">{{config.address}}&nbsp;</div>
                        <div class="cont">&nbsp;</div>
                    </div>
                    <div class="list ewm">
                        <img :src="config.wx_offiaccount_url" alt=""/>
                        <div class="ewm">&nbsp;</div>
                    </div>
                    <div class="list ewm">
                        <img :src="config.wx_mini_url" alt=""/>
                        <div class="ewm">&nbsp;</div>
                    </div>
                </div>
            </div>
            <div class="forTheRecord">
                {{config.copyright}}
                <div style="display:inline-block;cursor: pointer" @click="beianhao">{{config.icp}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import axios from "axios";

export default {
    data() {
        return {
            isBanner: true,
            config: {
                address: '',
                banner: '',
                bottom_banner: '',
                copyright: '',
                domain: '',
                email: '',
                hotline_phone: '',
                icp: '',
                logo: '',
                name: '',
                phone: '',
                website_title: '',
                wx_mini_url: '',
                wx_offiaccount_url: '',
            }
        }
    },
    computed: {
        key() {
            let arr = ['/profession', '/subject', '/subject-analyse', '/subject']
            this.isBanner = arr.indexOf(this.$route.path) == -1
            return this.$route.path
        }
    },
    created() {
        let local = new URL(window.location.href)
        let that = this
        axios.get(
            'https://oss.shudaozaixian.com/cache/agent_cms_info/list/' + local.hostname + '.json?time=' + new Date().getTime(), {}
        ).then(function (res) {
            if (res.data.domain) {
                let url = 'https://oss.shudaozaixian.com/cache/agent_cms_info/data/' + res.data.domain + '/config.json?time=' + new Date().getTime()
                axios.get(url, {}).then(function (response) {
                    if (response.data) {
                        that.config = response.data
                    }
                }).catch(function (error) {
                });
            }
        }).catch(function (err) {
        });
    },
    methods: {
        beianhao() {
            window.open('https://beian.miit.gov.cn/', '_blank')
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.btmMsg {
    width: 100%;
    margin: 0 auto;
    background-color: #f5f4f8;
    
    .info_model {
        width: 1230px;
        margin: 0 auto;
        
        .msg_info {
            width: 1230px;
            margin: 0 auto;
            //background: #eeeeee;
            
            .msg {
                margin: 0 auto;
                width: 100%;
                height: 210px;
                display: flex;
                font-size: 18px;
                justify-content: space-around;
                align-items: center;
                color: var(--font-3);
                
                .list {
                    display: flex;
                    flex-direction: column;
                    
                    .title {
                        color: var(--font-1);
                        font-weight: 500;
                        margin-bottom: 5px;
                    }
                    
                    .cont {
                        margin-top: 15px;
                        cursor: pointer;
                    }
                }
                
                .ewm {
                    font-size: 14px;
                    color: var(--font-1);
                    align-items: center;
                    
                    img {
                        width: 110px;
                        height: 110px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
        
        .forTheRecord {
            width: 100%;
            text-align: center;
            line-height: 69px;
            height: 69px;
            background: linear-gradient(90.08deg, #e93a29 1.09%, #ef6830 98.67%);
            font-size: 16px;
            color: #ffc0b7;
            display: flex;
            justify-content: space-around;
        }
        
        .banner {
            width: 100%;
            
            img {
                width: 100%;
                height: 208px;
            }
        }
    }
}
</style>
