import {createRouter, createWebHistory} from 'vue-router'
import ChoseCollege from '../views/ChoseCollege'
import Layout from "@/layout/index.vue";

const routes = [
    {
        path: '/',
        name: 'Admin',
        // redirect: '/login',
        redirect: '/home',
        component: Layout,
        meta: {
            title: '升学规划系统'
        },
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue'),
                meta: {
                    title: '升学规划系统'
                },
            },
            {
                path: "/volunteer",
                component: () => import("@/views/volunteer/index.vue"),
                name: "volunteer",
            },
            {
                path: "/weici",
                component: () => import("@/views/yifenyidang/index.vue"),
                name: "位次",
            },
            {
                path: "/pici",
                component: () => import("@/views/picixian/index.vue"),
                name: "批次",
            },
            {
                path: "/intention",
                component: () => import("@/views/personal/index.vue"),
                name: "intention",
                meta: {
                    title: '我的志愿'
                },
            },
            {
                path: "/personal",
                component: () => import("@/views/personal/personal.vue"),
                name: "Personal",
                meta: {
                    title: '个人中心'
                },
            },
            {
                path: "/message",
                component: () => import("@/views/message/index.vue"),
                name: "Message",
            },
            {
                path: "/tools",
                component: () => import("@/views/tools/index.vue"),
                name: "Tools",
                meta: {
                    title: '高考工具箱'
                },
            },
            {
                path: "/news",
                component: () => import("@/views/tools/news.vue"),
                name: "news",
                meta: {
                    title: '高考资讯'
                },
            },
            {
                path: "/profession",
                component: () => import("@/views/profession/index.vue"),
                name: "Profession",
            },
            {
                path: "/subject-analyse",
                component: () => import("@/views/subjectAnalyse/index.vue"),
                name: "SubjectAnalyse",
            },
            {
                path: "/subject",
                component: () => import("@/views/subject/index.vue"),
                name: "Subject",
            },
            {
                path: "/university",
                component: () => import("@/views/university/index.vue"),
                name: "University",
            },
            {
                path: "/chose_batch",
                component: () => import("@/views/chose_batch/chose_batch.vue"),
                name: "ChoseBatch",
                meta: {
                    title: '选择批次'
                },
            },
            {
                path: "/set_report_info",
                component: () => import("@/views/chose_batch/SetReportInfo.vue"),
                name: "SetReportInfo",
                meta: {
                    title: '设置报告信息'
                },
            },
            {
                path: "/set_report_list",
                component: () => import("@/views/chose_batch/SetReportList.vue"),
                name: "SetReportList",
                meta: {
                    title: '设置报告信息'
                },
            },
            {
                path: '/chose_school',
                name: 'ChoseSchool',
                component: () => import("@/views/ChoseSchool.vue"),
                meta: {
                    title: '选志愿'
                },
            },
            {
                path: '/buy_report_times',
                name: 'BuyReportTimes',
                component: () => import("@/views/BuyReportTimes.vue"),
                meta: {
                    title: '选志愿'
                },
            },
            {
                path: '/assess_info',
                name: 'Assess_Info',
                //component: () => import("@/views/Assess_Info.vue"),
                component: () => import("@/views/ChoseMajor.vue"),
                meta: {
                    title: 'AI选专业'
                },
            },
            {
                path: '/subject_analysis',
                name: 'SubjectAnalysis',
                // component: () => import('@/views/SubjectAnalysis.vue'),
                component: () => import('@/views/ChoseSubject.vue'),
                meta: {
                    title: '高一选科'
                },
            },
            {
                path: '/report_list',
                name: 'report_list',
                // component: () => import('@/views/SubjectAnalysis.vue'),
                component: () => import('@/views/ReportList.vue'),
                meta: {
                    title: '辅助选科'
                },
            },
            // {
            //     path: '/schools',
            //     name: 'Schools',
            //     //component: () => import(/* webpackChunkName: "about" */ '../views/Schools.vue'),
            //     component: () => import('@/views/Schools.vue'),
            //     meta: {
            //         title: '查大学'
            //     },
            // },
            {
                path: '/home_page',
                name: 'HomePage',
                component: () => import(/* webpackChunkName: "about" */ '@/views/HomePage.vue'),
                meta: {
                    title: '志愿列表'
                },
            },
            {
                path: '/goods',
                name: 'Goods',
                component: () => import(/* webpackChunkName: "about" */ '../views/Goods.vue'),
                meta: {
                    title: '开通VIP'
                },
            },
            {
                path: '/school_info',
                name: 'SchoolInfo',
                component: () => import(/* webpackChunkName: "about" */ '../views/university/index.vue'),
                meta: {
                    title: '学校详情'
                },
            },
            {
                path: '/article_info',
                name: 'ArticleInfo',
                component: () => import(/* webpackChunkName: "about" */ '../views/university/article.vue'),
                meta: {
                    title: '详情'
                },
            },
        ]
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
        meta: {
            title: '注册账户'
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
        meta: {
            title: '登录系统后台'
        },
    },
    {
        path: '/weixin',
        name: 'Weixin',
        component: () => import(/* webpackChunkName: "about" */ '../views/Weixin.vue'),
        meta: {
            title: '登录系统后台'
        },
    },
    // {
    //     path: '/assess_info',
    //     name: 'Assess_Info',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/Assess_Info.vue'),
    //     meta: {
    //         title: '测评选专业'
    //     },
    // },
    // {
    //     path: '/subject_analysis',
    //     name: 'SubjectAnalysis',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/SubjectAnalysis.vue'),
    //     meta: {
    //         title: '辅助选科'
    //     },
    // },
    {
        path: '/chose_ahead',
        name: 'ChoseAhead',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChoseAhead.vue'),
        meta: {
            title: '提前批'
        },
    },
    // {
    //     path: '/open_login',
    //     name: 'Open_Login',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/WXLogin.vue'),
    //     meta: {
    //         title: '登录系统后台'
    //     },
    // },
    {
        path: '/chose_college',
        name: 'ChoseCollege',
        component: ChoseCollege,
        meta: {
            title: '选志愿'
        },
    },
    // {
    //     path: '/chose_school',
    //     name: 'ChoseSchool',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/ChoseSchool.vue'),
    //     meta: {
    //         title: '本科普通批'
    //     },
    // },
    // {
    //     path: '/schools',
    //     name: 'Schools',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/Schools.vue'),
    //     meta: {
    //         title: '查大学'
    //     },
    // },
    // {
    //     path: '/goods',
    //     name: 'Goods',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/Goods.vue'),
    //     meta: {
    //         title: '开通VIP'
    //     },
    // },
    // {
    //     path: '/majors',
    //     name: 'Majors',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/Majors.vue'),
    //     meta: {
    //         title: '查专业'
    //     },
    // },
    {
        path: '/chose_school_plan',
        name: 'ChoseSchoolPlan',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChoseSchoolPlan.vue'),
        meta: {
            title: '本科普通批'
        },
    },
    {
        path: '/chose_art',
        name: 'ChoseArt',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChoseArt.vue'),
        meta: {
            title: '体育-艺术类'
        },
    },

    {
        path: '/school_map',
        name: 'SchoolMap',
        component: () => import(/* webpackChunkName: "about" */ '../views/SchoolMap.vue'),
        meta: {
            title: '地理位置'
        },
    },
    {
        path: '/chose_vocation',
        name: 'ChoseVocation',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChoseVocation.vue'),
        meta: {
            title: '专科批'
        },
    },
    // {
    //     path: '/favorite_list',
    //     name: 'FavoriteList',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/FavoriteList.vue'),
    //     meta: {
    //         title: '专家列表'
    //     },
    // },
    // {
    //     path: '/home_page',
    //     name: 'HomePage',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/HomePage.vue'),
    //     meta: {
    //         title: '志愿填报'
    //     },
    // },
    // {
    //     path: '/report_list',
    //     name: 'Report_List',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/ReportList.vue'),
    //     meta: {
    //         title: '收藏志愿列表'
    //     },
    // },
    // {
    //     path: '/goods',
    //     name: 'Goods',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/Goods.vue'),
    //     meta: {
    //         title: '商品列表'
    //     },
    // },
    {
        path: '/approval',
        name: 'Approval',
        component: () => import(/* webpackChunkName: "about" */ '../views/Approval.vue'),
        meta: {
            title: '审核志愿'
        },
    },
    {
        path: '/report',
        name: 'Report',
        component: () => import(/* webpackChunkName: "about" */ '../views/Report.vue'),
        meta: {
            title: '我的志愿报告'
        },
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
        meta: {
            title: '首页'
        },
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHistory(),
    routes
})

export default router
