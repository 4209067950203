<template>
    <section class="app-main">
            <router-view
                :key="key"
                :add_base_info_status="add_base_info_status"
                @change_menu_action="change_menu_action"
                @change_bind_phone="change_bind_phone_fun"
                :change_province_status="change_province_status"/>
    </section>
</template>

<script>
export default {
    name: 'AppMain',
    props:{
        change_province_status:{
            type: Number,
            default: 0
        },
        add_base_info_status:{
            type: Number,
            default: 0
        },
        change_bind_phone:{
            type: Function,
            default: null
        }
    },
    computed: {
        key() {
            return this.$route.path
        }
    },
    methods:{
        change_menu_action(info){
            this.$emit('change_menu_action', info)
        },
        change_bind_phone_fun(info){
            //console.log("change_bind_phone(info)")
            this.$emit('change_bind_phone', info)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.app-main {
    min-height: calc(100vh - 680px);
    position: relative;
    overflow: hidden;
    background: var(--main-bg);
}
</style>
