import env from '@/utils/env'

export default {
    school: {
        area: 'school/area',
        brief_list: 'school/brief_list',
        type: 'school/type',
        level: 'school/level',
        list: 'school/list',
        label: 'school/label',
        list_json: 'school/list.json',
        school_label_json: 'school_label/list.json',
        extra:'school/extra',
        enroll_info:'school/enroll_info',
        enroll_list:'school/enroll_list',
        first_class:'school/first_class',
        special_major:'school/special_major',
        subject_review:'school/subject_review',
        department:'school/department',
        jobs_report:'school/jobs_report',
        plans:'school/plans',
        scores:'school/scores',
        base_info:'school/base_info',
    },
    province: {
        list_json: 'province/list.json',
    },
    major: {
        list: 'major/list',
        search: 'major/search',
        list_json: 'majors/list.json',
    },
    open_wx: {
        access_token: 'open-wx/access-token',
    },
    oss: {
        version: env[process.env.NODE_ENV].OSS_URL + 'cache/v.json',
        list: env[process.env.NODE_ENV].OSS_URL + 'cache/list.json',
        sheng_list: env[process.env.NODE_ENV].OSS_URL + 'cache/list_placeholder.json',
        agent_info: env[process.env.NODE_ENV].OSS_URL + 'cache/agent_cms_info/v/__domain__.json',
        agent_config: env[process.env.NODE_ENV].OSS_URL + 'cache/agent_cms_info/data/__domain__/config_placeholder.json',
        agent_home: env[process.env.NODE_ENV].OSS_URL + 'cache/agent_cms_info/data/__domain__/home_placeholder.json',
    },
    personality: {
        save_code: 'personality/save_code',
        hld: 'personality/hld',
        hld_desc: 'personality/hld_desc',
        major_hld: 'personality/major_hld',
        major_direction: 'personality/major_direction',
    },
    user: {
        login: 'user/login',
        report_suggest: 'user/report_suggest',
        report_suggest_list: 'user/report_suggest_list',
        save_report_suggest: 'user/save_report_suggest',
        send_phone_code_by_forget_pass: 'user/send_phone_code_by_forget_pass',
        send_code_by_phone_register: 'user/send_code_by_phone_register',
        send_code_by_phone_code_login: 'user/send_code_by_phone_code_login',
        phone_register: 'user/phone_register',
        reset_password: 'user/reset_password',
        card_login: 'user/card_login',
        phone_login: 'user/phone_login',
        get_card_num: 'user/get_card_num',
        upload_collection: 'user/upload_collection',
        power: 'user/power',
        plan_show_type: 'user/plan_show_type',
        optional_major_analysis: 'user/optional_major_analysis',
        get_major_analysis: 'user/get_major_analysis',
        need_login: 'user/need_login',
        get_report: 'user/get_report',
        get_report_test: 'user/get_report_test',
        scan_status: 'user/scan_status',
        change_password: 'user/change_password',
        register: 'user/register',
        input: 'user/input',
        input_score: 'user/input_score',
        major_analysis: 'user/major_analysis',
        extra_info: 'user/extra_info',
        areas: 'user/areas',
        favorite: 'user/favorite',
        un_favorite: 'user/un_favorite',
        favorites: 'user/favorites',
        save_change_favorites: 'user/save_change_favorites',
        send_code: 'user/send_code',
        search_param: 'user/search_param',
        set_search_param: 'user/set_search_param',
        collection_school: 'user/collection_school',
        member_collection: 'user/member_collection',
        approval_info: 'user/approval_info',
        approval_list: 'user/approval_list',
        send_2_instructor: 'user/send_2_instructor',
        send_report: 'user/send_report',
        binding_approval: 'user/binding_approval',
        collect_sort_change: 'user/collect_sort_change',
        get_phone_code: 'user/get_phone_code',
        send_phone_code: 'user/send_phone_code',
        info: 'user/info',
        report_list: 'user/report_list',
        examinees_application_info: 'user/examinees_application_info',
        application_list: 'user/application_list',
        application_form_change_name: 'user/application_form_change_name',
        application_form_delete: 'user/application_form_delete',
        open_experience_vip: 'user/open_experience_vip',
        remove_report: 'user/remove_report',
        report_info: 'user/report_info',
        login_qr_code: 'user/login_qr_code',
        get_agent: 'user/get_agent',
        get_agreement: 'user/get_agreement',
        recommend_rank: 'user/recommend_rank',
        favorites_class: 'user/favorites_class',
        personality_test: 'user/personality_test',
        save_plans: 'user/save_plans',
    },
    system: {
        status: 'system/status',
        config: 'system/config',
    },
    member: {
        collections: 'member/collections',
        collect: 'member/collect',
    },
    system_tips: {
        list: 'system_tips/list',
    },
    open_major: {
        majors_info: 'open_major/majors_info',
        list: 'open_major/list',
        major_info: 'open_major/major_info',
        school_info: 'open_major/school_info',
    },
    junior_major: {
        list: 'junior_major/list',
        major_info: 'junior_major/major_info',
    },
    favorite: {
        change: 'favorite/change',
        download: 'favorite/download',
    },
    search_param: {
        province: 'search_param/province',
        school_list: 'search_param/school_list',
        majors: 'search_param/majors',
        school_label: 'search_param/school_label',
    },
    match: {
        list: 'match/list',
        majors: 'match/majors',
    },
    goods: {
        list: 'goods/list',
        report_good: 'goods/report_good',
        order: 'goods/order',
        buy_vip: 'goods/buy_vip',
        default_agent_buy_vip: 'goods/default_agent_buy_vip',
        default_agent_buy_vip_status: 'goods/default_agent_buy_vip_status',
        buy_report_times: 'goods/buy_report_times',
        default_agent_buy_report_times: 'goods/default_agent_buy_report_times',
        default_agent_buy_report_times_status: 'goods/default_agent_buy_report_times_status',
        buy_report_times_status: 'goods/buy_report_times_status',
        h5_order: 'goods/h5_order',
        buy_vip_pay_status: 'goods/buy_vip_pay_status',
        pay_status: 'goods/pay_status',
        check_coupon: 'goods/check_coupon',
    },
    cms:{
        article_list: 'cms/article_list',
        article_info: 'cms/article_info',
    },
    algorithm: {
        collect: 'algorithm/collect'
    },
    instructor: {
        pending_approval: 'instructor/pending_approval',
        student_info: 'instructor/student_info',
        assess_plan: 'instructor/assess_plan',
        send_2_member: 'instructor/send_2_member',
        update_summary: 'instructor/update_summary',
        review: 'instructor/review',
    }
}
